import { EventStatus } from '@bellepoque/api-contracts';
import { Box, styled, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Joyride, { Step } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';

import { eventStatusToTranslation } from '../../../../utils/event-status-to-translation';
import { eventToUserJourneyData } from '../../../../utils/event-to-user-journey-data';
import { makeAutoplayUrl } from '../../../../utils/replay-links';
import LiveWithChat from '../../../components/organisms/event/event-details/LiveWithChat';
import StyledDialog from '../../../templates/dialog/StyledDialog';
import { createOnboardingLiveManagerViewModel } from './OnboardingLiveManager.viewmodel';

const Container = styled(Box)({
  flex: 1,
  height: '100%',
});

const tourStep: Step = {
  content: '',
  disableBeacon: true,
  disableCloseOnEsc: true,
  disableOverlayClose: true,
  floaterProps: {
    styles: {
      floater: {
        display: 'none',
      },
    },
  },
  spotlightClicks: true,
  target: `.onboarding-tour-highlighted`,
};

const OnboardingLiveManager = () => {
  const { t } = useTranslation('onboarding', { keyPrefix: 'Steps.LiveManager' });
  const { t: tEvents } = useTranslation('events');
  const theme = useTheme();
  const viewModel = useSelector(createOnboardingLiveManagerViewModel({ dispatch: useDispatch() }));
  const { event, fetchLandingPage, landingPageUrl, tenantId, tenantName, trackUserJourneyEvent } = viewModel;

  const [isBeforePrivateTestModalShown, setIsBeforePrivateTestModalShown] = useState(false);
  const [isInPrivateTestModalShown, setIsInPrivateTestModalShown] = useState(false);
  const [isOnAirModalShown, setIsOnAirModalShown] = useState(false);

  useEffect(() => {
    if (event?.status) {
      trackUserJourneyEvent({
        data: {
          ...eventToUserJourneyData(event),
          'Tenant id': tenantId,
          'Tenant name': tenantName,
        },
        name: `Onboarding - Live Manager - ${eventStatusToTranslation(event.status, tEvents)}`,
      });
    }
  }, [event?.status]);

  useEffect(() => {
    if (landingPageUrl === '') {
      fetchLandingPage();
    }
  }, [landingPageUrl]);

  useEffect(() => {
    if (event) {
      setIsBeforePrivateTestModalShown(event.status === EventStatus.PLANNED);
      setIsInPrivateTestModalShown(event.status === EventStatus.PRIVATE_PREVIEW);
      setIsOnAirModalShown(event.status === EventStatus.ON_AIR);
    }
  }, [event?.status]);

  const handleCloseOnAirModal = useCallback(() => {
    if (event) {
      const autoplayUrl = makeAutoplayUrl({
        baseUrl: landingPageUrl,
        eventId: event.id,
      });
      window.open(autoplayUrl, '_blank', 'noreferrer');
      setIsOnAirModalShown(false);
    }
  }, [event, landingPageUrl]);

  if (!event) return null;

  return (
    <Container>
      <Joyride
        run={isBeforePrivateTestModalShown || isInPrivateTestModalShown}
        steps={[tourStep]}
        styles={{ overlay: { zIndex: theme.zIndex.drawer + 2 } }}
      />
      <LiveWithChat
        disableOpenToPublicConfirmationModal
        event={event}
        sx={{
          height: `100%`,
        }}
      />
      <StyledDialog
        allowClicksThroughBackdrop
        content={t('BeforePrivateTestModal.Content')}
        disableBackdropClose
        hiddenBackdrop
        onClose={() => setIsBeforePrivateTestModalShown(false)}
        open={isBeforePrivateTestModalShown}
        title={t('BeforePrivateTestModal.Title')}
      />
      <StyledDialog
        allowClicksThroughBackdrop
        content={t('InPrivateTestModal.Content')}
        disableBackdropClose
        hiddenBackdrop
        onClose={() => setIsInPrivateTestModalShown(false)}
        open={isInPrivateTestModalShown}
        title={t('InPrivateTestModal.Title')}
      />
      <StyledDialog
        ButtonsProps={[
          {
            children: t('WatchOnMyShopify'),
            onClick: handleCloseOnAirModal,
            variant: 'contained',
          },
        ]}
        content={t('OnAirModal.Content')}
        disableBackdropClose
        onClose={handleCloseOnAirModal}
        open={isOnAirModalShown}
        title={t('OnAirModal.Title')}
      />
    </Container>
  );
};

export default OnboardingLiveManager;
