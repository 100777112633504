import { EventId, EventStatus, HIGHLIGHTABLE_STATUSES, V2 } from '@bellepoque/api-contracts';
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CreateEventFormDialog from '../../components/organisms/event/create-event-form-dialog/CreateEventFormDialog';
import LiveShoppingPageTemplate from '../../templates/LiveShoppingTemplate';
import { createLiveShoppablePageViewModel } from './LiveShoppingPage.viewmodel';

const constraints = V2.api.constraints.landingPage;

interface FormValues {
  highlightedEventsIds: string[];
  landingPageUrl: string;
  publishedReplayEventsIds: string[];
  replaySectionTitle: string;
}

const defaultValues: FormValues = {
  highlightedEventsIds: [],
  landingPageUrl: '',
  publishedReplayEventsIds: [],
  replaySectionTitle: '',
};

type LiveShoppingPageProps = {
  isDrawerOpen: boolean;
};

export default function LiveShoppingPage({ isDrawerOpen }: LiveShoppingPageProps) {
  const { t } = useTranslation(['liveShoppingPage', 'common']);
  const viewModel = useSelector(createLiveShoppablePageViewModel({ dispatch: useDispatch() }));

  const {
    canHighlightMultipleEvents,
    checkHasCmsLandingPage,
    cmsLandingPageCreateStatus,
    createCmsLandingPage,
    currentPlanId,
    events,
    eventsFetchingStatus,
    fetchEvents,
    fetchLandingPage,
    hasCmsLandingPage,
    hasCmsLandingPageFetchingStatus,
    landingPageData,
    landingPageDataFetchingStatus,
    landingPageDataUpdateStatus,
    maxPublishedReplayDurationInSeconds,
    maxPublishedReplays,
    tenantCms,
    tenantId,
    tenantName,
    trackUserJourneyEvent,
    updateLandingPage,
  } = viewModel;

  const landingPageUrl = landingPageData.landingPageUrl;

  const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false);

  const loading =
    landingPageDataFetchingStatus === 'pending' ||
    eventsFetchingStatus === 'pending' ||
    hasCmsLandingPageFetchingStatus === 'pending';

  const hasFetchError =
    landingPageDataFetchingStatus === 'error' ||
    eventsFetchingStatus === 'error' ||
    hasCmsLandingPageFetchingStatus === 'error';

  const { register, formState, handleSubmit, reset, setValue, watch } = useForm<FormValues>({
    defaultValues,
  });
  const { errors, isDirty } = formState;

  const { ref: replaySectionTitleRef, ...replaySectionTitleProps } = register('replaySectionTitle', {
    maxLength: {
      message: t('common:MaxCharsWithCount', { count: constraints.replaySectionTitle.maxLength }),
      value: constraints.replaySectionTitle.maxLength,
    },
    minLength: {
      message: t('common:MinCharsWithCount', { count: constraints.replaySectionTitle.minLength }),
      value: constraints.replaySectionTitle.minLength,
    },
    required: {
      message: t('Replays.SectionTitleRequired'),
      value: true,
    },
  });

  const highlightableEvents = useMemo(
    () => events.filter(({ status }) => HIGHLIGHTABLE_STATUSES.includes(status)),
    [events],
  );
  const replayEvents = useMemo(() => events.filter(({ status }) => status === EventStatus.REPLAY), [events]);

  useEffect(() => {
    trackUserJourneyEvent();
  }, []);

  useEffect(() => {
    if (hasCmsLandingPage && tenantId !== '') {
      fetchLandingPage();
      fetchEvents();
    }
  }, [tenantId, hasCmsLandingPage]);

  useEffect(() => {
    reset(landingPageData);
  }, [landingPageData]);

  const handleChangeHighlightedEvents = (highlightedEventsIds: EventId[]) =>
    setValue('highlightedEventsIds', highlightedEventsIds, { shouldDirty: true });

  const handleChangePublishedReplays = (publishedReplayEventsIds: EventId[]) =>
    setValue('publishedReplayEventsIds', publishedReplayEventsIds, { shouldDirty: true });

  const handleCancel = () => reset(landingPageData);

  const handleCreateCmsLandingPage = () => {
    createCmsLandingPage();
  };

  const handleRetryFailedFetch = () => {
    if (hasCmsLandingPageFetchingStatus === 'error') {
      checkHasCmsLandingPage();
    }

    if (landingPageDataFetchingStatus === 'error') {
      fetchLandingPage();
    }

    if (eventsFetchingStatus === 'error') {
      fetchEvents();
    }
  };

  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => updateLandingPage(data);

  return (
    <>
      <CreateEventFormDialog onClose={() => setIsCreateEventModalOpen(false)} open={isCreateEventModalOpen} />

      <LiveShoppingPageTemplate
        canHighlightMultipleEvents={canHighlightMultipleEvents}
        cmsLandingPageCreationInProgress={cmsLandingPageCreateStatus === 'pending'}
        currentPlanId={currentPlanId}
        formErrors={errors}
        hasCmsLandingPage={hasCmsLandingPage}
        hasFetchError={hasFetchError}
        highlightableEvents={highlightableEvents}
        isDirty={isDirty}
        isDrawerOpen={isDrawerOpen}
        landingPageData={watch()}
        landingPageUrl={landingPageUrl}
        loading={loading}
        maxPublishedReplayDurationInSeconds={maxPublishedReplayDurationInSeconds}
        maxPublishedReplays={maxPublishedReplays}
        onCancel={handleCancel}
        onChangeHighlightedEvents={handleChangeHighlightedEvents}
        onChangePublishedReplays={handleChangePublishedReplays}
        onCreateCmsLandingPage={handleCreateCmsLandingPage}
        onOpenEventCreationModal={() => setIsCreateEventModalOpen(true)}
        onRetryFailedFetch={() => handleRetryFailedFetch()}
        onSubmit={handleSubmit(onSubmit)}
        replayEvents={replayEvents}
        replaySectionTitleProps={replaySectionTitleProps}
        replaySectionTitleRef={replaySectionTitleRef}
        tenantCms={tenantCms}
        tenantName={tenantName}
        updateInProgress={landingPageDataUpdateStatus === 'pending'}
      />
    </>
  );
}
