import { TenantId } from '@bellepoque/api-contracts';
import { Home, StarBorder } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { CSSObject, Theme, alpha, styled } from '@mui/material/styles';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { CBOTenant } from '../../../core/domain/CBOTenant';
import { CurrentUserType } from '../../../core/store/state/authentication';
import AdministrationIcon from '../../../icons/AdministrationIcon';
import AnalyticsIcon from '../../../icons/AnalyticsIcon';
import CatalogIcon from '../../../icons/CatalogIcon';
import EventsIcon from '../../../icons/EventsIcon';
import HelpAndSupportIcon from '../../../icons/HelpAndSupportIcon';
import OnSiteVideosIcon from '../../../icons/OnSiteVideosIcon';
import ReplaysIcon from '../../../icons/ReplaysIcon';
import ShoppablesIcon from '../../../icons/ShoppablesIcon';
import { NavigationTabs } from '../../navigation-tabs';
import { routes } from '../../routes';
import MiniDrawerItem from '../atoms/MiniDrawerItem';
import TenantSelector from '../atoms/inputs/TenantSelector';

export const closedDrawerWidth = 72;
export const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.enteringScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: drawerWidth,
});

const closedMixin = (theme: Theme): CSSObject => ({
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: closedDrawerWidth,
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  boxSizing: 'border-box',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  width: drawerWidth,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),

  '.MuiDrawer-paper': {
    backgroundColor: `${theme.palette.primary.dark} !important`,
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: alpha(theme.palette.common.white, 0.3),
  margin: `${theme.spacing(0.5)} auto`,
  width: '92%',
}));

const StyledList = styled(List)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
}));

const TenantSelectorContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  width: 'calc(100% - 32px)',
}));

interface MiniDrawerProps {
  currentTenant: CBOTenant;
  currentUser: CurrentUserType;
  disablePointerEvents: boolean;
  enableEventsOnly: boolean;
  highlightedTab: NavigationTabs | null;
  isLiveShoppingPageEnabled: boolean;
  onChangeTenant: (tenantId: TenantId) => void;
  onCloseDrawer: () => void;
  open: boolean;
  tenants: CBOTenant[];
}

export default function MiniDrawer(props: MiniDrawerProps) {
  const { t } = useTranslation(['navigation']);

  const {
    currentTenant,
    disablePointerEvents,
    enableEventsOnly,
    highlightedTab,
    isLiveShoppingPageEnabled,
    onChangeTenant,
    onCloseDrawer,
    open,
    tenants,
  } = props;

  const location = useLocation();
  const selectedTab: NavigationTabs | null = useMemo(() => {
    const tabs = Object.values(NavigationTabs);
    for (const tab of tabs) {
      if (location.pathname.split('/')?.[1] === tab) {
        return tab;
      }
    }
    return null;
  }, [location]);

  return (
    <Drawer open={open} sx={{ pointerEvents: disablePointerEvents ? 'none' : 'auto' }} variant="permanent">
      <DrawerHeader>
        <IconButton onClick={onCloseDrawer} size="large">
          <ChevronLeftIcon htmlColor="white" />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <StyledList aria-label="home">
        <MiniDrawerItem
          icon={<Home />}
          isDrawerClosed={!open}
          isHighlighted={highlightedTab === NavigationTabs.Home}
          isSelected={selectedTab === NavigationTabs.Home}
          title={t('Home')}
          to={routes.home}
        />
      </StyledList>
      <StyledDivider />
      <StyledList aria-label="events">
        <MiniDrawerItem
          icon={<EventsIcon />}
          isDrawerClosed={!open}
          isHighlighted={highlightedTab === NavigationTabs.Events}
          isSelected={selectedTab === NavigationTabs.Events}
          title={t('Events')}
          to={routes.events.root}
        />
      </StyledList>
      <StyledList aria-label="replays">
        <MiniDrawerItem
          icon={<ReplaysIcon />}
          isDrawerClosed={!open}
          isHighlighted={highlightedTab === NavigationTabs.Replays}
          isSelected={selectedTab === NavigationTabs.Replays}
          title={t('Replays')}
          to={routes.replays.root}
        />
      </StyledList>
      {isLiveShoppingPageEnabled && (
        <StyledList aria-label="live-shopping-page">
          <MiniDrawerItem
            icon={<OndemandVideoIcon strokeWidth={0} />}
            isDisabled={enableEventsOnly}
            isDrawerClosed={!open}
            isHighlighted={highlightedTab === NavigationTabs.LiveShoppingPage}
            isSelected={selectedTab === NavigationTabs.LiveShoppingPage}
            title={t('LiveShoppingPage')}
            to={routes.liveShoppingPage}
          />
        </StyledList>
      )}
      <StyledDivider />
      <StyledList aria-label="shoppables">
        <MiniDrawerItem
          icon={<ShoppablesIcon sx={{ color: (theme) => theme.palette.common.white }} />}
          isDrawerClosed={!open}
          isHighlighted={highlightedTab === NavigationTabs.Shoppables}
          isSelected={selectedTab === NavigationTabs.Shoppables}
          title={t('Shoppables')}
          to={routes.shoppables}
        />
      </StyledList>
      {isLiveShoppingPageEnabled && (
        <StyledList aria-label="playlists">
          <MiniDrawerItem
            icon={<OnSiteVideosIcon />}
            isDrawerClosed={!open}
            isHighlighted={highlightedTab === NavigationTabs.Playlists}
            isSelected={selectedTab === NavigationTabs.Playlists}
            releaseBadge={{
              status: 'updated',
            }}
            title={t('OnSiteVideos')}
            to={routes.playlists.root}
          />
        </StyledList>
      )}
      <StyledDivider />
      <StyledList aria-label="administration">
        <MiniDrawerItem
          icon={<AdministrationIcon />}
          isDisabled={enableEventsOnly}
          isDrawerClosed={!open}
          isHighlighted={highlightedTab === NavigationTabs.Administration}
          isSelected={selectedTab === NavigationTabs.Administration}
          title={t('Administration')}
          to={routes.administration.root}
        />
      </StyledList>
      <StyledList aria-label="analytics">
        <MiniDrawerItem
          icon={<AnalyticsIcon />}
          isDisabled={enableEventsOnly}
          isDrawerClosed={!open}
          isHighlighted={highlightedTab === NavigationTabs.Analytics}
          isSelected={selectedTab === NavigationTabs.Analytics}
          title={t('Analytics')}
          to={routes.analytics.root}
        />
      </StyledList>
      {!isLiveShoppingPageEnabled && (
        <StyledList aria-label="catalog">
          <MiniDrawerItem
            icon={<CatalogIcon />}
            isDisabled={enableEventsOnly}
            isDrawerClosed={!open}
            isHighlighted={highlightedTab === NavigationTabs.Catalog}
            isSelected={selectedTab === NavigationTabs.Catalog}
            title={t('Catalog')}
            to={routes.catalog}
          />
        </StyledList>
      )}
      <StyledList aria-label="integrations">
        <MiniDrawerItem
          icon={<StarBorder />}
          isDisabled={enableEventsOnly}
          isDrawerClosed={!open}
          isHighlighted={highlightedTab === NavigationTabs.Integrations}
          isSelected={selectedTab === NavigationTabs.Integrations}
          title={t('Integrations')}
          to={routes.integrations.root}
        />
      </StyledList>
      <StyledList aria-label="help-and-support">
        <MiniDrawerItem
          icon={<HelpAndSupportIcon />}
          isDisabled={enableEventsOnly}
          isDrawerClosed={!open}
          isHighlighted={highlightedTab === NavigationTabs.HelpAndSupport}
          isSelected={selectedTab === NavigationTabs.HelpAndSupport}
          title={t('HelpAndSupport')}
          to={routes.helpAndSupport({})}
        />
      </StyledList>
      {tenants.length > 1 && (
        <StyledList aria-label="tenant-selection">
          <TenantSelectorContainer>
            <TenantSelector currentTenant={currentTenant} onChange={onChangeTenant} tenants={tenants} />
          </TenantSelectorContainer>
        </StyledList>
      )}
      <div style={{ flexGrow: 1 }} />
    </Drawer>
  );
}
