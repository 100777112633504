import { BillingPlanId, EventId } from '@bellepoque/api-contracts';
import { Box, Grid } from '@mui/material';
import React from 'react';
import { RefCallBack } from 'react-hook-form';

import { CBOEventListReadModel } from '../../../../core/domain/CBOEventListReadModel';
import { CBOLandingPageData } from '../../../../core/domain/CBOLandingPageData';
import StickyActionBarWrapper from '../../../templates/StickyActionBarWrapper';
import ActionBar from '../../molecules/live-shopping-page/ActionBar';
import HighlightSection from '../../molecules/live-shopping-page/HighlightSection';
import ReplaySection from '../../molecules/live-shopping-page/ReplaySection';

interface LiveShoppingPageConfigurationProps {
  canHighlightMultipleEvents: boolean;
  currentPlanId: BillingPlanId;
  formErrors: any;
  highlightableEvents: CBOEventListReadModel[];
  isDirty: boolean;
  isFooterShown: boolean;
  landingPageData: CBOLandingPageData;
  loading: boolean;
  maxPublishedReplayDurationInSeconds?: number;
  maxPublishedReplays?: number;
  onCancel: () => void;
  onChangeHighlightedEvents: (eventIds: EventId[]) => void;
  onChangePublishedReplays: (eventIds: EventId[]) => void;
  onOpenEventCreationModal: () => void;
  onSubmit: () => void;
  replayEvents: CBOEventListReadModel[];
  replaySectionTitleProps: any;
  replaySectionTitleRef: RefCallBack;
  submissionInProgress: boolean;
  tenantName: string;
}

export default function LiveShoppingPageConfiguration({
  canHighlightMultipleEvents,
  currentPlanId,
  formErrors,
  highlightableEvents,
  isDirty,
  isFooterShown,
  landingPageData,
  loading,
  maxPublishedReplayDurationInSeconds,
  maxPublishedReplays,
  onCancel,
  onChangeHighlightedEvents,
  onChangePublishedReplays,
  onOpenEventCreationModal,
  onSubmit,
  replayEvents,
  replaySectionTitleProps,
  replaySectionTitleRef,
  submissionInProgress,
  tenantName,
}: LiveShoppingPageConfigurationProps) {
  return (
    <Box flexDirection="column" flexGrow={1} marginTop={15} visibility={loading ? 'hidden' : 'visible'}>
      <Grid container display="flex" flex={1} justifyContent="center" pb={5} px={3}>
        <Grid item lg={10} md={10} xl={8} xs={12}>
          <HighlightSection
            canHighlightMultipleEvents={canHighlightMultipleEvents}
            currentPlanId={currentPlanId}
            events={highlightableEvents}
            highlightedEventsIds={landingPageData.highlightedEventsIds}
            onChangeHighlightedEvents={onChangeHighlightedEvents}
            onOpenEventCreationModal={onOpenEventCreationModal}
            submissionInProgress={submissionInProgress}
            tenantName={tenantName}
          />
          <ReplaySection
            currentPlanId={currentPlanId}
            formErrors={formErrors}
            maxPublishedReplayDurationInSeconds={maxPublishedReplayDurationInSeconds}
            maxPublishedReplays={maxPublishedReplays}
            onChangePublishedReplays={onChangePublishedReplays}
            publishedReplayEventsIds={landingPageData.publishedReplayEventsIds}
            replaySectionTitleProps={replaySectionTitleProps}
            replaySectionTitleRef={replaySectionTitleRef}
            replaySectionTitleValue={landingPageData.replaySectionTitle}
            replays={replayEvents}
            submissionInProgress={submissionInProgress}
            tenantName={tenantName}
          />
        </Grid>
      </Grid>
      {isFooterShown && (
        <StickyActionBarWrapper inProgress={submissionInProgress}>
          <ActionBar
            areButtonsDisabled={submissionInProgress}
            isFormDirty={isDirty}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        </StickyActionBarWrapper>
      )}
    </Box>
  );
}
