export interface CBOLiveProductsStatistics {
  [productId: string]: CBOLiveProductStatistics;
}

export interface CBOLiveProductStatistics {
  countLiveCartQuantity: number;
  nbProductPageViews: number;
  totalLiveCartAmount: number;
}

export interface CBOLiveEventStatistics {
  byProductIds: CBOLiveProductsStatistics;
  countActiveChatUsers: number;
  countActiveViewers: number;
  countLiveCartQuantity: number;
  countLiveLikes: number;
  countLiveProductPageOpened: number;
  countLiveValidateCart: number;
  totalLiveCartAmount: number;
  totalLivePurchasedAmount: number;
  totalLiveUniqueViewers: number;
}

export const initialLiveEventStatistics: CBOLiveEventStatistics = {
  byProductIds: {},
  countActiveChatUsers: 0,
  countActiveViewers: 0,
  countLiveCartQuantity: 0,
  countLiveLikes: 0,
  countLiveProductPageOpened: 0,
  countLiveValidateCart: 0,
  totalLiveCartAmount: 0,
  totalLivePurchasedAmount: 0,
  totalLiveUniqueViewers: 0,
};
