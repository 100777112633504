import { EventId } from '@bellepoque/api-contracts';
import ReceiptIcon from '@mui/icons-material/Receipt';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';

import ReplaysIcon from '../../../icons/ReplaysIcon';
import ShoppablesIcon from '../../../icons/ShoppablesIcon';
import { TabProps } from '../../components/molecules/Tabs';
import TenantEventAnalytics from '../../components/organisms/analytics/TenantEventAnalytics';
import TenantShoppableVideoAnalytics from '../../components/organisms/analytics/TenantShoppableVideoAnalytics';
import CreateEventFormDialog from '../../components/organisms/event/create-event-form-dialog/CreateEventFormDialog';
import Billing from '../../components/organisms/reports/Billing';
import TrafficReports from '../../components/organisms/reports/TrafficReports';
import ShoppableFormDialog from '../../components/organisms/shoppables/shoppable-form-dialog/ShoppableFormDialog';
import { routes } from '../../routes';
import AnalyticsTemplate from '../../templates/analytics/AnalyticsTemplate';
import { createAnalyticsViewModel } from './Analytics.viewmodel';

export enum AnalyticsTabsNumber {
  LiveAndReplays = 1,
  ShoppableVideos = 2,
  Traffic = 3,
  Usage = 4,
}

type AnalyticsProps = {
  isDrawerOpen: boolean;
};

export default function Analytics({ isDrawerOpen }: AnalyticsProps) {
  const { t } = useTranslation('analytics');
  const viewModel = useSelector(createAnalyticsViewModel({ dispatch: useDispatch() }));

  const {
    fetchTenantStatistics,
    isAdmin,
    isTutorialOpen,
    tenantCurrency,
    tenantId,
    tenantName,
    tenantStatistics,
    tenantStatisticsFetchingStatus,
  } = viewModel;

  const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false);
  const [isCreateShoppableVideoModalOpen, setIsCreateShoppableVideoModalOpen] = useState(false);

  const isUsagePageActive = useMatch(routes.analytics.usage);
  const isLiveAndReplaysAnalyticsPageActive = useMatch(routes.analytics.liveAndReplays);
  const isShoppableVideosAnalyticsPageActive = useMatch(routes.analytics.shoppableVideos);
  const isTrafficPageActive = useMatch(routes.analytics.traffic);

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<number>(() => {
    if (isLiveAndReplaysAnalyticsPageActive) return AnalyticsTabsNumber.LiveAndReplays;
    if (isShoppableVideosAnalyticsPageActive) return AnalyticsTabsNumber.ShoppableVideos;
    if (isTrafficPageActive) return AnalyticsTabsNumber.Traffic;
    if (isUsagePageActive) return AnalyticsTabsNumber.Usage;
    return AnalyticsTabsNumber.LiveAndReplays;
  });

  useEffect(() => {
    fetchTenantStatistics();
  }, [tenantId]);

  useEffect(() => {
    const tab = tabs.find((tab) => tab.index === activeTab);
    if (tab) navigate(tab.to);
  }, [activeTab]);

  const isLoading = () => {
    return tenantStatisticsFetchingStatus === 'pending';
  };

  const handleChangeTab = (tab: AnalyticsTabsNumber) => {
    setActiveTab(tab);
  };

  const handleShowEventStatistics = (eventId: EventId) => {
    navigate(routes.analytics.event({ eventId }));
  };

  const tabs: TabProps[] = [
    {
      component: (
        <TenantEventAnalytics
          currency={tenantCurrency}
          loading={isLoading()}
          onCreateEvent={() => setIsCreateEventModalOpen(true)}
          onShowEventStatistics={handleShowEventStatistics}
          tenantId={tenantId}
          tenantName={tenantName}
          tenantStatistics={tenantStatistics}
        />
      ),
      enabled: true,
      icon: <ReplaysIcon />,
      index: AnalyticsTabsNumber.LiveAndReplays,
      label: t('LiveAndReplays'),
      to: routes.analytics.liveAndReplays,
    },
    {
      component: (
        <TenantShoppableVideoAnalytics
          currency={tenantCurrency}
          loading={isLoading()}
          onCreateShoppableVideo={() => setIsCreateShoppableVideoModalOpen(true)}
          tenantId={tenantId}
          tenantName={tenantName}
          tenantStatistics={tenantStatistics}
        />
      ),
      enabled: true,
      icon: <ShoppablesIcon />,
      index: AnalyticsTabsNumber.ShoppableVideos,
      label: t('ShoppableVideos'),
      to: routes.analytics.shoppableVideos,
    },
    {
      component: <TrafficReports />,
      enabled: true,
      icon: <ReceiptIcon />,
      index: AnalyticsTabsNumber.Traffic,
      label: t('common:TrafficReports'),
      to: routes.analytics.traffic,
    },
    ...(isAdmin
      ? [
          {
            component: <Billing />,
            enabled: true,
            icon: <ReceiptIcon />,
            index: AnalyticsTabsNumber.Usage,
            label: t('common:Usage'),
            to: routes.analytics.usage,
          },
        ]
      : []),
  ].filter((x) => x);

  return (
    <>
      <CreateEventFormDialog onClose={() => setIsCreateEventModalOpen(false)} open={isCreateEventModalOpen} />
      <ShoppableFormDialog
        formType="create"
        onClose={() => setIsCreateShoppableVideoModalOpen(false)}
        open={isCreateShoppableVideoModalOpen}
      />
      <AnalyticsTemplate
        activeTab={activeTab}
        isDrawerOpen={isDrawerOpen}
        isTutorialOpen={isTutorialOpen}
        onChangeTab={handleChangeTab}
        onCreateEvent={() => setIsCreateEventModalOpen(true)}
        onCreateShoppableVideo={() => setIsCreateShoppableVideoModalOpen(true)}
        tabs={tabs}
      />
    </>
  );
}
