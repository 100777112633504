import React, { FC, Fragment, useEffect, useState } from 'react';

import { CBOEventListReadModel } from '../../../../core/domain/CBOEventListReadModel';
import { CBOMediaCollection } from '../../../../core/domain/CBOMediaCollection';
import { CBOMediaCollectionMedia } from '../../../../core/domain/CBOMediaCollectionMedia';
import { CBOShoppableVideoListReadModel } from '../../../../core/domain/CBOShoppableVideoReadModel';
import { CommandStatus } from '../../../../core/store/state/utils';
import { CreateMediaCollectionRequest } from '../../../../core/usecases/media-collections/create-media-collection';
import { UpdateMediaCollectionSettingsRequest } from '../../../../core/usecases/media-collections/update-media-collection-settings';
import PlayerPreviewDialog, { PlayerPreviewParams } from '../../../components/organisms/PlayerPreviewDialog';
import UpgradePlanDialog from '../../../components/organisms/UpgradePlanDialog';
import AddPublishedPagesToMediaCollectionDialog from '../../../components/organisms/media-collections/add-published-pages-to-media-collection-dialog/AddPublishedPagesToMediaCollectionDialog';
import MediaCollectionFormLayout from '../../../components/organisms/media-collections/media-collections-editor/MediaCollectionFormLayout';
import { MediaCollectionsEditorFormType } from '../../../pages/media-collections/media-collections-editor/MediaCollectionsEditor';

export type MediaCollectionsEditorTemplateProps = {
  areReplayChaptersFetched: boolean;
  areReplaysFetched: boolean;
  areShoppablesFetched: boolean;
  canEnableInfiniteMediaCollection: boolean;
  canSelectReplaysOnMediaCollection: boolean;
  creationStatus: CommandStatus;
  currentMediaCollection: CBOMediaCollection | null;
  formType: MediaCollectionsEditorFormType;
  maxPublishedShoppablesCount: number | null;
  mediaCollectionPagesUpdateStatus: CommandStatus;
  mediaCollectionSettingsUpdateStatus: CommandStatus;
  mediaCollections: CBOMediaCollection[];
  onCreateMediaCollection: (payload: CreateMediaCollectionRequest['payload']) => void;
  onFetchReplayChapters: (replaysIds: string[]) => void;
  onGoToEventCreationPage: () => void;
  onGoToMediaCollection: (mediaCollectionId: string) => void;
  onGoToMediaCollectionList: () => void;
  onGoToShoppableCreationPage: () => void;
  onUpdateMediaCollectionSettings: (payload: UpdateMediaCollectionSettingsRequest['payload']) => void;
  publishedShoppablesCount: number;
  replays: CBOEventListReadModel[];
  shoppables: CBOShoppableVideoListReadModel;
  tenantId: string;
  tenantName: string;
};

type CurrentlyPlayedMedia = PlayerPreviewParams & {
  videoId: string;
};

const MediaCollectionsEditorTemplate: FC<MediaCollectionsEditorTemplateProps> = ({
  areReplayChaptersFetched,
  areReplaysFetched,
  areShoppablesFetched,
  canEnableInfiniteMediaCollection,
  canSelectReplaysOnMediaCollection,
  creationStatus,
  currentMediaCollection,
  formType,
  maxPublishedShoppablesCount,
  mediaCollectionPagesUpdateStatus,
  mediaCollections,
  mediaCollectionSettingsUpdateStatus,
  onCreateMediaCollection,
  onFetchReplayChapters,
  onGoToEventCreationPage,
  onGoToMediaCollection,
  onGoToMediaCollectionList,
  onGoToShoppableCreationPage,
  onUpdateMediaCollectionSettings,
  publishedShoppablesCount,
  replays,
  shoppables,
  tenantId,
  tenantName,
}) => {
  const [isAddPublishedPagesToMediaCollectionDialogOpen, setIsPublishedPagesToMediaCollectionDialogOpen] =
    useState(false);
  const [isUpgradePlanDialogOpen, setIsUpgradePlanDialogOpen] = useState(false);
  const [currentlyPlayedMedia, setCurrentlyPlayedMedia] = useState<CurrentlyPlayedMedia | null>(null);
  const [isPlayerMediaCollectionModalOpen, setIsPlayerMediaCollectionModalOpen] = useState<boolean>(false);

  const handleClosePublishedPagesToMediaCollectionDialog = () => {
    setIsPublishedPagesToMediaCollectionDialogOpen(false);
  };

  const handleOpenPublishedPagesEditor = () => {
    setIsPublishedPagesToMediaCollectionDialogOpen(true);
  };

  const handleDisplayUpdgradePlanDialog = () => {
    setIsUpgradePlanDialogOpen(true);
  };

  const handleCloseUpgradePlanDialog = () => {
    setIsUpgradePlanDialogOpen(false);
  };

  const handleMediaPreviewClick = (media: CBOMediaCollectionMedia) => {
    const videoId = media.type === 'replay-chapter' ? media.replayId : media.id;
    const startTimeStamp = media.type === 'replay-chapter' ? media.startTimeStamp / 1000 : undefined;
    const endTimeStamp = media.type === 'replay-chapter' ? media.endTimeStamp / 1000 : undefined;

    setCurrentlyPlayedMedia({ endTimeStamp, startTimeStamp, videoId });
    setIsPlayerMediaCollectionModalOpen(true);
  };

  useEffect(() => {
    if (mediaCollectionPagesUpdateStatus === 'success') {
      handleClosePublishedPagesToMediaCollectionDialog();
    }
  }, [mediaCollectionPagesUpdateStatus]);

  return (
    <Fragment>
      <MediaCollectionFormLayout
        areReplayChaptersFetched={areReplayChaptersFetched}
        areReplaysFetched={areReplaysFetched}
        areShoppablesFetched={areShoppablesFetched}
        canEnableInfiniteMediaCollection={canEnableInfiniteMediaCollection}
        canSelectReplaysOnMediaCollection={canSelectReplaysOnMediaCollection}
        creationStatus={creationStatus}
        currentMediaCollection={currentMediaCollection}
        formType={formType}
        maxPublishedShoppablesCount={maxPublishedShoppablesCount}
        mediaCollectionPagesUpdateStatus={mediaCollectionPagesUpdateStatus}
        mediaCollectionSettingsUpdateStatus={mediaCollectionSettingsUpdateStatus}
        mediaCollections={mediaCollections}
        onCreate={onCreateMediaCollection}
        onFetchReplayChapters={onFetchReplayChapters}
        onGoToEventCreationPage={onGoToEventCreationPage}
        onGoToMediaCollection={onGoToMediaCollection}
        onGoToMediaCollectionList={onGoToMediaCollectionList}
        onGoToShoppableCreationPage={onGoToShoppableCreationPage}
        onMediaPreviewClick={handleMediaPreviewClick}
        onOpenPublishedPagesEditor={handleOpenPublishedPagesEditor}
        onUpdateSettings={onUpdateMediaCollectionSettings}
        publishedShoppablesCount={publishedShoppablesCount}
        replays={replays}
        shoppables={shoppables}
        tenantId={tenantId}
        tenantName={tenantName}
      />

      <AddPublishedPagesToMediaCollectionDialog
        isOpen={isAddPublishedPagesToMediaCollectionDialogOpen}
        onClose={handleClosePublishedPagesToMediaCollectionDialog}
        onDisplayUpdgradePlanDialog={handleDisplayUpdgradePlanDialog}
      />

      {maxPublishedShoppablesCount && (
        <UpgradePlanDialog
          isOpen={isUpgradePlanDialogOpen}
          maxPublishedShoppablesCount={maxPublishedShoppablesCount}
          onClose={handleCloseUpgradePlanDialog}
          tenantName={tenantName}
        />
      )}

      {!!currentlyPlayedMedia?.videoId && (
        <PlayerPreviewDialog
          isCollection
          onClose={() => setIsPlayerMediaCollectionModalOpen(false)}
          open={isPlayerMediaCollectionModalOpen}
          {...currentlyPlayedMedia}
        />
      )}
    </Fragment>
  );
};

export default MediaCollectionsEditorTemplate;
