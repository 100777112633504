import { Box, ListItemButton, ListItemIcon, ListItemText, styled } from '@mui/material';
import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

import ReleaseBadge from './ReleaseBadge';
import HighlightedMenuArrow from './tutorial/HighlightedMenuArrow';

const Container = styled(Box)({
  position: 'relative',
  width: '100%',
});

const HighlightedArrowContainer = styled(Box)({
  position: 'absolute',
  right: '-10px',
  top: 'calc(50% - 15px)',
});

const StyledListItemButton = styled(ListItemButton, { shouldForwardProp: (prop) => prop !== 'highlighted' })<{
  highlighted: boolean;
}>(({ highlighted, theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  borderRadius: '4px',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,

  position: 'relative',

  ...(highlighted && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

const StyledListItemIcon = styled(ListItemIcon, { shouldForwardProp: (prop) => prop !== 'isDrawerClosed' })<{
  isDrawerClosed: boolean;
}>(({ isDrawerClosed, theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.white,
  minWidth: isDrawerClosed ? '24px' : '40px',
  stroke: theme.palette.common.white,
}));

const StyledListItemText = styled(ListItemText, { shouldForwardProp: (prop) => prop !== 'isDrawerClosed' })<{
  isDrawerClosed: boolean;
}>(({ isDrawerClosed, theme }) => ({
  color: theme.palette.common.white,
  opacity: isDrawerClosed ? 0 : 1,
}));

const ReleaseBadgeContainer = styled(Box)(({ theme }) => ({
  pointerEvents: 'none',
  position: 'absolute',
  right: theme.spacing(2),
  top: '50%',
  transform: 'translate(0, -50%)',
}));

interface ListItemLinkProps {
  icon?: React.ReactElement;
  isDisabled?: boolean;
  isDrawerClosed: boolean;
  isHighlighted: boolean;
  isSelected: boolean;
  releaseBadge?: {
    newFeaturesNumber?: number;
    status: 'new' | 'soon' | 'updated';
  };
  title: string;
  to: string;
}

export default function MiniDrawerItem({
  icon,
  isDisabled,
  isDrawerClosed,
  isHighlighted,
  isSelected,
  releaseBadge,
  title,
  to,
}: ListItemLinkProps) {
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(function Link(itemProps, ref) {
        return <RouterLink ref={ref} to={to} {...itemProps} role={undefined} />;
      }),
    [to],
  );

  return (
    <Container>
      <StyledListItemButton
        //@ts-ignore
        component={renderLink}
        disabled={isDisabled}
        highlighted={isHighlighted || isSelected}
      >
        {icon ? <StyledListItemIcon isDrawerClosed={isDrawerClosed}>{icon}</StyledListItemIcon> : null}
        <StyledListItemText isDrawerClosed={isDrawerClosed} primary={title} />
      </StyledListItemButton>
      {releaseBadge && !isDrawerClosed && (
        <ReleaseBadgeContainer>
          <ReleaseBadge {...releaseBadge} />
        </ReleaseBadgeContainer>
      )}
      {isHighlighted && (
        <HighlightedArrowContainer>
          <HighlightedMenuArrow size={30} style={{ position: 'fixed' }} />
        </HighlightedArrowContainer>
      )}
    </Container>
  );
}
