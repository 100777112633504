import { BILLING_PLANS, BillingPlanId } from '@bellepoque/api-contracts';

import { AppDispatch, State } from '../../../../core/store';
import { fetchReplaysChapters } from '../../../../core/usecases/event/chapters/fetch-replays-chapters';
import { fetchEvents } from '../../../../core/usecases/event/crud/fetch-events';
import {
  CreateMediaCollectionRequest,
  createMediaCollection,
  resetCreateMediaCollection,
} from '../../../../core/usecases/media-collections/create-media-collection';
import { fetchMediaCollections } from '../../../../core/usecases/media-collections/fetch-media-collections';
import { fetchOneMediaCollection } from '../../../../core/usecases/media-collections/fetch-one-media-collection';
import { resetUpdateMediaCollectionPages } from '../../../../core/usecases/media-collections/update-media-collection-pages';
import {
  UpdateMediaCollectionSettingsRequest,
  resetUpdateMediaCollectionSettings,
  updateMediaCollectionSettings,
} from '../../../../core/usecases/media-collections/update-media-collection-settings';
import { getPagesForMediaCollections } from '../../../../core/usecases/shopify/get-pages-for-media-collections';
import { fetchShoppables } from '../../../../core/usecases/shoppables/fetch-shoppables';
import { trackUserJourneyEvent } from '../../../../core/usecases/user-journey-tracing/track-user-journey-event';
import { isReplay } from '../../../../utils/is-replay';

export const createMediaCollectionsEditorViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const {
      currentMediaCollection,
      mediaCollectionCreation,
      mediaCollectionFetching: { status: mediaCollectionFetchingStatus },
      mediaCollectionPagesUpdate: { status: mediaCollectionPagesUpdateStatus },
      mediaCollections,
      mediaCollectionSettingsUpdate: { status: mediaCollectionSettingsUpdateStatus },
      publishedShoppablesCount,
    } = state.mediaCollections;
    const { activeSubscriptionId, id: tenantId, name: tenantName } = state.tenants.currentTenant;
    const {
      shoppables,
      shoppableCreation: { status: shoppableCreationStatus },
      shoppablesFetching: { status: shoppablesFetchingStatus },
    } = state.shoppables;
    const {
      eventsFetching: { status: eventsFetchingStatus },
    } = state.events;

    const replays = state.events.events.filter(({ status }) => isReplay(status));

    const currentPlanId: BillingPlanId = activeSubscriptionId ?? 'live_access';

    return {
      areReplayChaptersFetched: state.events.replaysChaptersFetching.status === 'loaded',
      areReplaysFetched: eventsFetchingStatus === 'loaded',
      areShoppablesFetched: shoppablesFetchingStatus === 'loaded',
      canEnableInfiniteMediaCollection: BILLING_PLANS[currentPlanId].canEnableInfiniteMediaCollection,
      canSelectReplaysOnMediaCollection: BILLING_PLANS[currentPlanId].canSelectReplaysOnMediaCollection,
      createMediaCollection: (payload: CreateMediaCollectionRequest['payload']) => {
        dispatch(createMediaCollection({ payload, tenantId }));
      },
      creationStatus: mediaCollectionCreation.status,
      currentMediaCollection,
      fetchEvents: () => {
        dispatch(fetchEvents({ eventsFetchingStatus, tenantId }));
      },
      fetchMediaCollections: () => {
        dispatch(fetchMediaCollections({ tenantId }));
      },
      fetchOneMediaCollection: (mediaCollectionId: string) => {
        dispatch(fetchOneMediaCollection({ mediaCollectionId, tenantId }));
      },
      fetchReplaysChapters: (replaysIds: string[]) => {
        dispatch(fetchReplaysChapters({ replaysIds, tenantId }));
      },
      fetchShoppables: () => {
        dispatch(fetchShoppables({ shoppablesFetchingStatus, tenantId }));
      },
      getPages: () => {
        dispatch(getPagesForMediaCollections(tenantId));
      },
      maxPublishedShoppablesCount: BILLING_PLANS[currentPlanId].maxPublishedShoppables ?? null,
      mediaCollectionFetchingStatus,
      mediaCollectionPagesUpdateStatus,
      mediaCollectionSettingsUpdateStatus,
      mediaCollections,
      publishedShoppablesCount,
      replays,
      resetCreate: () => {
        dispatch(resetCreateMediaCollection());
      },
      resetUpdate: () => {
        dispatch(resetUpdateMediaCollectionPages());
        dispatch(resetUpdateMediaCollectionSettings());
      },
      shoppableCreationStatus,
      shoppables,
      tenantId,
      tenantName,
      trackUserJourneyEvent: (collectionId: string, collectionName: string) => {
        dispatch(
          trackUserJourneyEvent({
            data: {
              ...(collectionId ? { 'Playlist id': collectionId } : {}),
              ...(collectionName ? { 'Playlist name': collectionName } : {}),
              'Tenant id': tenantId,
              'Tenant name': tenantName,
            },
            name: collectionId ? 'Edit playlist' : 'Create playlist',
          }),
        );
      },
      updateMediaCollectionSettings: (payload: UpdateMediaCollectionSettingsRequest['payload']) => {
        if (currentMediaCollection)
          dispatch(updateMediaCollectionSettings({ mediaCollectionId: currentMediaCollection.id, payload, tenantId }));
      },
    };
  };
