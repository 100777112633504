import { browserTracingIntegration } from '@sentry/browser';
import * as Sentry from '@sentry/react';

import { SetContextParams, TracingGateway } from '../../core/gateways/tracing-gateway';

const enabled = process.env.REACT_APP_CBO_SENTRY_ENABLED === 'true';
const tracesSampleRate = process.env.REACT_APP_CBO_SENTRY_SAMPLE_RATE
  ? +process.env.REACT_APP_CBO_SENTRY_SAMPLE_RATE
  : 0.2;

export default class SentryTracingGateway implements TracingGateway {
  constructor(dsn: string) {
    Sentry.init({
      dsn,
      enabled,
      integrations: [browserTracingIntegration({})],
      maxBreadcrumbs: 20,
      maxValueLength: 50,
      normalizeDepth: 5,
      release: `customer-backoffice@X.X.X`,
      tracesSampleRate,
    });
  }

  captureException(error: Error) {
    Sentry.captureException(error);
  }

  setContext(params: SetContextParams): void {
    Sentry.setContext('custom', params);
    Sentry.setTag('context', params.context);
  }
}
