import { TenantId } from '@bellepoque/api-contracts';
import '@fontsource/roboto';
import '@fontsource/ubuntu/400.css';
import '@fontsource/ubuntu/500.css';
import '@fontsource/ubuntu/700.css';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './App.css';
import { createAppViewModel } from './App.viewmodel';
import RequestsNotifications from './components/RequestsNotifications';
import Menu from './components/organisms/Menu';
import { NavigationTabs } from './navigation-tabs';

declare global {
  interface Window {
    $crisp: any;
  }
}

export const App = () => {
  const viewModel = useSelector(createAppViewModel({ dispatch: useDispatch() }));

  const {
    checkHasCmsLandingPage,
    checkShopifyScopesAreUpToDate,
    currentTenant,
    currentTutorialStep,
    currentUser,
    highlightedTab,
    isAuthenticated,
    isTutorialShown,
    login,
    selectTenant,
    initUserJourneyTracing,
    tenants,
    logout,
    nextTutorialStep,
    toggleTutorial,
    trackUserJourneyEvent,
  } = viewModel;

  useEffect(() => {
    if (!isAuthenticated) {
      login();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (currentTenant.name && isAuthenticated)
      try {
        window.$crisp.push(['set', 'user:nickname', [`${currentUser.email}---${currentTenant.name}`]]);
        window.$crisp.push(['set', 'user:email', [currentUser.email]]);
      } catch (e) {
        console.error('Error while adding informations to Crisp profile', e);
      }
  }, [currentTenant.name, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && currentTenant.id !== '') {
      initUserJourneyTracing();
    }
  }, [isAuthenticated, currentTenant.id]);

  if (isAuthenticated !== true) {
    return <>Loading...</>;
  }

  useEffect(() => {
    if (currentTenant.id !== '') {
      checkHasCmsLandingPage();
    }
  }, [currentTenant.id]);

  useEffect(() => {
    if (currentTenant.cms === 'SHOPIFY') {
      checkShopifyScopesAreUpToDate();
    }
  }, [currentTenant.id]);

  useEffect(() => {
    if (currentTenant.id) {
      trackUserJourneyEvent({
        data: {
          path: location.pathname,
          tenantId: currentTenant.id,
          tenantName: currentTenant.name,
        },
        name: 'Page view',
      });
    }
  }, [location.pathname]);

  const handleChangeTenant = (tenantId: TenantId) => {
    selectTenant(tenantId);
  };

  const handleCloseTutorial = () => {
    toggleTutorial(false);
  };

  const handleLogout = () => {
    logout();
  };

  const handleNextTutorialStep = (params: { highlightedTab: NavigationTabs | null }) => {
    nextTutorialStep(params);
  };

  return (
    <SnackbarProvider
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      maxSnack={1}
    >
      <div className="App" id="App">
        <Menu
          currentTenant={currentTenant}
          currentTutorialStep={currentTutorialStep}
          currentUser={currentUser}
          highlightedTab={highlightedTab}
          isTutorialShown={isTutorialShown}
          onChangeTenant={handleChangeTenant}
          onCloseTutorial={handleCloseTutorial}
          onLogout={handleLogout}
          onNextTutorialStep={handleNextTutorialStep}
          tenants={tenants}
        />
        <RequestsNotifications />
      </div>
    </SnackbarProvider>
  );
};
