import { Backdrop, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useRequiredParams } from '../../../../utils/useRequiredParams';
import CreateEventFormDialog from '../../../components/organisms/event/create-event-form-dialog/CreateEventFormDialog';
import ShoppableFormDialog from '../../../components/organisms/shoppables/shoppable-form-dialog/ShoppableFormDialog';
import { routes } from '../../../routes';
import MediaCollectionsEditorTemplate from '../../../templates/media-collections/media-collections-editor/MediaCollectionsEditorTemplate';
import { createMediaCollectionsEditorViewModel } from './MediaCollectionsEditor.viewmodel';

export type MediaCollectionsEditorFormType = 'creation' | 'edition';

const MediaCollectionsEditor: React.FC = () => {
  const viewModel = useSelector(createMediaCollectionsEditorViewModel({ dispatch: useDispatch() }));

  const {
    areReplaysFetched,
    areReplayChaptersFetched,
    areShoppablesFetched,
    canEnableInfiniteMediaCollection,
    canSelectReplaysOnMediaCollection,
    createMediaCollection,
    creationStatus,
    currentMediaCollection,
    fetchEvents,
    fetchMediaCollections,
    fetchOneMediaCollection,
    fetchReplaysChapters,
    fetchShoppables,
    getPages,
    maxPublishedShoppablesCount,
    mediaCollectionFetchingStatus,
    mediaCollectionPagesUpdateStatus,
    mediaCollections,
    mediaCollectionSettingsUpdateStatus,
    publishedShoppablesCount,
    replays,
    resetCreate,
    resetUpdate,
    shoppableCreationStatus,
    shoppables,
    tenantId,
    tenantName,
    trackUserJourneyEvent,
    updateMediaCollectionSettings,
  } = viewModel;

  const navigate = useNavigate();

  const [isCreateEventVideoModalOpen, setIsCreateEventModalOpen] = useState<boolean>(false);
  const [isCreateShoppableVideoModalOpen, setIsCreateShoppableVideoModalOpen] = useState<boolean>(false);
  const { mediaCollectionId } = useRequiredParams<{ mediaCollectionId: string }>();

  const formType: MediaCollectionsEditorFormType = mediaCollectionId ? 'edition' : 'creation';

  const loading = formType === 'edition' && mediaCollectionFetchingStatus === 'pending';

  const handleGoToMediaCollectionList = () => {
    navigate(routes.playlists.root);
  };

  const handleGoToMediaCollection = (collectionId: string) => {
    navigate(routes.playlists.mediaCollection({ mediaCollectionId: collectionId }));
  };

  const handleGoToEventCreationPage = () => {
    setIsCreateEventModalOpen(true);
  };

  const handleGoToShoppableCreationPage = () => {
    setIsCreateShoppableVideoModalOpen(true);
  };

  useEffect(() => {
    if (shoppableCreationStatus === 'success') {
      navigate(routes.shoppables);
      setIsCreateShoppableVideoModalOpen(false);
    }
  }, [shoppableCreationStatus]);

  useEffect(() => {
    // TODO: Don't fetch pages if they're already loaded
    getPages();
    fetchMediaCollections();
    fetchEvents();
    fetchShoppables();
  }, [tenantId]);

  useEffect(() => {
    if (
      formType === 'edition' &&
      mediaCollectionFetchingStatus === 'loaded' &&
      currentMediaCollection?.tenantId !== tenantId
    ) {
      handleGoToMediaCollectionList();
    }
  }, [tenantId, currentMediaCollection?.tenantId, mediaCollectionFetchingStatus]);

  useEffect(() => {
    if (!mediaCollectionId) {
      trackUserJourneyEvent('-', '-');
    } else if (currentMediaCollection) {
      trackUserJourneyEvent(currentMediaCollection.id, currentMediaCollection.title);
    }
  }, [mediaCollectionId, currentMediaCollection?.id]);

  useEffect(() => {
    if (formType === 'edition' && currentMediaCollection?.id !== mediaCollectionId) {
      fetchOneMediaCollection(mediaCollectionId);
    }
  }, [mediaCollectionId, formType]);

  useEffect(() => {
    if (formType === 'edition' && mediaCollectionFetchingStatus === 'error') {
      handleGoToMediaCollectionList();
    }
  }, [mediaCollectionFetchingStatus, formType]);

  useEffect(() => {
    if (
      ['success', 'error'].includes(mediaCollectionPagesUpdateStatus) ||
      ['success', 'error'].includes(mediaCollectionSettingsUpdateStatus)
    ) {
      resetUpdate();
    }
  }, [mediaCollectionPagesUpdateStatus, mediaCollectionSettingsUpdateStatus]);

  useEffect(() => {
    if (['success', 'error'].includes(creationStatus)) {
      resetCreate();
    }
  }, [creationStatus]);

  const handleFetchReplaysChapters = (replaysIds: string[]) => {
    fetchReplaysChapters(replaysIds);
  };

  return (
    <>
      <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress />
      </Backdrop>
      {isCreateShoppableVideoModalOpen && (
        <ShoppableFormDialog
          formType="create"
          onClose={() => setIsCreateShoppableVideoModalOpen(false)}
          open={isCreateShoppableVideoModalOpen}
        />
      )}
      <CreateEventFormDialog onClose={() => setIsCreateEventModalOpen(false)} open={isCreateEventVideoModalOpen} />
      {!loading && (
        <MediaCollectionsEditorTemplate
          areReplayChaptersFetched={areReplayChaptersFetched}
          areReplaysFetched={areReplaysFetched}
          areShoppablesFetched={areShoppablesFetched}
          canEnableInfiniteMediaCollection={canEnableInfiniteMediaCollection}
          canSelectReplaysOnMediaCollection={canSelectReplaysOnMediaCollection}
          creationStatus={creationStatus}
          currentMediaCollection={currentMediaCollection}
          formType={formType}
          maxPublishedShoppablesCount={maxPublishedShoppablesCount}
          mediaCollectionPagesUpdateStatus={mediaCollectionPagesUpdateStatus}
          mediaCollectionSettingsUpdateStatus={mediaCollectionSettingsUpdateStatus}
          mediaCollections={mediaCollections}
          onCreateMediaCollection={createMediaCollection}
          onFetchReplayChapters={handleFetchReplaysChapters}
          onGoToEventCreationPage={handleGoToEventCreationPage}
          onGoToMediaCollection={handleGoToMediaCollection}
          onGoToMediaCollectionList={handleGoToMediaCollectionList}
          onGoToShoppableCreationPage={handleGoToShoppableCreationPage}
          onUpdateMediaCollectionSettings={updateMediaCollectionSettings}
          publishedShoppablesCount={publishedShoppablesCount}
          replays={replays}
          shoppables={shoppables}
          tenantId={tenantId}
          tenantName={tenantName}
        />
      )}
    </>
  );
};

export default MediaCollectionsEditor;
