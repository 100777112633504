import { EventId } from '@bellepoque/api-contracts';
import { Box, Grid, LinearProgress, styled } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import { CBOTenantStatistics, CBOTenantStatisticsGuards } from '../../../../core/domain/CBOTenantStatistics';
import { trackUserJourneyEvent } from '../../../../core/usecases/user-journey-tracing/track-user-journey-event';
import { hasBeenLiveEvent } from '../../../../utils/has-been-live-event';
import BlurOverlay from '../../../templates/BlurOverlay';
import ActionDialog from '../../../templates/dialog/ActionDialog';
import CreateButton from '../../atoms/CreateButton';
import EventECommerceSection from './EventECommerceSection';
import EventEngagementSection from './EventEngagementSection';
import EventIntroSection from './EventIntroSection';
import EventListSection from './EventListSection';
import EventTrafficSection from './EventTrafficSection';

const Root = styled(Box)(({ theme }) => ({
  ...theme.typography.titleFont,
  flexGrow: 1,
  position: 'relative',
  textAlign: 'initial',
}));

type TenantEventAnalyticsProps = {
  currency: CBOCurrency;
  loading: boolean;
  onCreateEvent: () => void;
  onShowEventStatistics: (eventId: EventId) => void;
  tenantId: string;
  tenantName: string;
  tenantStatistics: CBOTenantStatistics;
};

export default function TenantEventAnalytics({
  currency,
  loading,
  onCreateEvent,
  onShowEventStatistics,
  tenantStatistics,
  tenantId,
  tenantName,
}: TenantEventAnalyticsProps) {
  const { t } = useTranslation(['analytics', 'events']);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      trackUserJourneyEvent({
        data: {
          'Tenant id': tenantId,
          'Tenant name': tenantName,
        },
        name: 'Analytics - Live and replays',
      }),
    );
  }, []);

  const hasSales = CBOTenantStatisticsGuards.isCBOTenantStatisticsWithSales(tenantStatistics);

  const hasLiveEvents = useMemo(
    () => tenantStatistics.events.eventStatisticsPreviews.some(({ status }) => hasBeenLiveEvent(status)),
    [tenantStatistics],
  );

  const renderEventSections = () => {
    const views = tenantStatistics.events.traffic.views.live + tenantStatistics.events.traffic.views.replay;

    return (
      <>
        <Grid container justifyContent="center">
          <Grid gap={2} item lg={11} md={11} xl={10} xs={12}>
            <Box display="flex" flex={1} flexDirection="column" gap={2}>
              {hasSales ? (
                <EventIntroSection
                  currency={currency}
                  hasSales
                  purchasedAmounts={
                    tenantStatistics.events.eCommerce.purchasedAmounts.live +
                    tenantStatistics.events.eCommerce.purchasedAmounts.replay
                  }
                  purchasedCarts={
                    tenantStatistics.events.eCommerce.purchasedCarts.live +
                    tenantStatistics.events.eCommerce.purchasedCarts.replay
                  }
                  views={views}
                />
              ) : (
                <EventIntroSection
                  currency={currency}
                  hasSales={false}
                  validatedCarts={
                    tenantStatistics.events.eCommerce.validatedCarts.live +
                    tenantStatistics.events.eCommerce.validatedCarts.replay
                  }
                  validatedCartsAmounts={
                    (tenantStatistics.events.eCommerce.validatedCartsAmounts?.live ?? 0) +
                    (tenantStatistics.events.eCommerce.validatedCartsAmounts?.replay ?? 0)
                  }
                  views={views}
                />
              )}
              <EventTrafficSection statistics={tenantStatistics.events.traffic} />
              <EventEngagementSection statistics={tenantStatistics.events.engagement} />
              {hasSales ? (
                <EventECommerceSection
                  currency={currency}
                  event={null}
                  hasSales
                  statistics={tenantStatistics.events.eCommerce}
                />
              ) : (
                <EventECommerceSection
                  currency={currency}
                  event={null}
                  hasSales={false}
                  statistics={tenantStatistics.events.eCommerce}
                />
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" mt={5} sx={{ backgroundColor: (theme) => theme.palette.common.white }}>
          <Grid gap={2} item md={10} mt={2} xs={12}>
            {tenantStatistics.events.eventStatisticsPreviews.length > 0 && (
              <EventListSection
                eventStatisticsPreviews={tenantStatistics.events.eventStatisticsPreviews}
                onShowEventStatistics={onShowEventStatistics}
              />
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Root>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          <BlurOverlay shown={!hasLiveEvents}>
            <ActionDialog
              actionButton={<CreateButton onClick={onCreateEvent} text={t('events:CreateAnEvent')} />}
              content={t('NoLiveReplayStatsDescription')}
              open={!hasLiveEvents}
              title={t('NoLiveReplayStats')}
            />
          </BlurOverlay>

          {renderEventSections()}
        </>
      )}
    </Root>
  );
}
