import { Typography, styled } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const ProductQuantityLabelContainer = styled(Typography, {
  shouldForwardProp: (prop) => !['quantity'].includes(prop.toString()),
})<{ quantity: number | null }>(({ theme, quantity }) => ({
  '&::before': {
    borderRadius: '50%',
    content: `''`,
    height: '6px',
    marginRight: '4px',
    width: '6px',
  },
  alignItems: 'center',
  display: 'flex',
  fontSize: '0.7em',

  fontWeight: 'bold',

  ...(quantity === 0 && {
    '&:before': {
      background: theme.palette.error.main,
    },

    color: theme.palette.error.main,
  }),
  ...(quantity === 1 && {
    '&:before': {
      background: theme.palette.warning.main,
    },

    color: theme.palette.warning.main,
  }),
  ...((quantity === null || quantity > 1) && {
    '&:before': {
      background: theme.palette.success.main,
    },

    color: theme.palette.success.main,
  }),
}));

export type ProductQuantityLabelProps = {
  quantity: number | null;
};

const ProductQuantityLabel: FC<ProductQuantityLabelProps> = ({ quantity }) => {
  const { t } = useTranslation('products');

  const quantityLabel = useMemo(() => {
    if (quantity === 0) return t('quantity.Unavailable');

    if (quantity === null) return t('quantity.Available');

    if (quantity === 1) return t('quantity.LastOne');

    return t('quantity.AvailableWithQuantity', { quantity });
  }, [t, quantity]);

  return <ProductQuantityLabelContainer quantity={quantity}>{quantityLabel}</ProductQuantityLabelContainer>;
};

export default ProductQuantityLabel;
