import { Facebook } from '@mui/icons-material';
import { Button, lighten, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface LinkFacebookAccountButtonProps {
  disabled: boolean;
  onClick: () => void;
}

const StyledButton = styled(Button)(({ disabled, theme }) => ({
  '&:disabled': {
    backgroundColor: lighten(theme.palette.socials.facebook, 0.5),
    color: theme.palette.common.white,
    opacity: 0.5,
  },
  '&:hover': {
    backgroundColor: lighten(theme.palette.socials.facebook, 0.2),
  },
  backgroundColor: disabled ? theme.palette.grey[500] : theme.palette.socials.facebook,
  color: theme.palette.common.white,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  textTransform: 'initial',
}));

export default function LinkFacebookAccountButton({ disabled, onClick }: LinkFacebookAccountButtonProps) {
  const { t } = useTranslation('events', { keyPrefix: 'FacebookRestreaming' });

  return (
    <StyledButton disabled={disabled} onClick={onClick} startIcon={<Facebook />}>
      {t('LinkAccount')}
    </StyledButton>
  );
}
