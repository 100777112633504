import { Box, ButtonProps } from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CBOMediaCollection } from '../../../../../core/domain/CBOMediaCollection';
import { shoppableVideoListItemReadModelToMediaCollectionMedia } from '../../../../../core/domain/CBOMediaCollectionMedia';
import { CBOShoppableVideoListItemReadModel } from '../../../../../core/domain/CBOShoppableVideoReadModel';
import { countPublishedShoppablesFromMediaCollections } from '../../../../../utils/count-published-shoppables-from-media-collections';
import FormDialog from '../../../../templates/dialog/FormDialog';
import PlayerMediaCollectionPreviewDialog, {
  PlayerMediaCollectionPreviewParams,
} from '../../PlayerMediaCollectionPreviewDialog';
import UpgradePlanDialog from '../../UpgradePlanDialog';
import MediaCollectionList from '../MediaCollectionList';
import AddPublishedPagesToMediaCollectionDialog from '../add-published-pages-to-media-collection-dialog/AddPublishedPagesToMediaCollectionDialog';
import { SelectMediaCollectionDialogViewModel } from './SelectMediaCollectionDialog.viewmodel';

export type SelectCollectionDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelectMediaCollections: (mediaCollections: CBOMediaCollection[]) => void;
};

const SelectMediaCollectionDialog: FC<SelectCollectionDialogProps> = ({
  isOpen,
  onClose,
  onSelectMediaCollections,
}) => {
  const { t } = useTranslation(['mediaCollections', 'common']);
  const viewModel = useSelector(SelectMediaCollectionDialogViewModel({ dispatch: useDispatch() }));

  const {
    canEnableInfiniteMediaCollection,
    currentMediaCollection,
    currentShoppable,
    fetchOneMediaCollection,
    getPages,
    maxPublishedShoppablesCount,
    mediaCollectionPagesUpdateStatus,
    mediaCollections,
    mediaCollectionSettingsUpdateStatus,
    tenantId,
    tenantName,
    updateMediaCollectionSettings,
  } = viewModel;

  const [isAddPublishedPagesToMediaCollectionDialogOpen, setIsAddPublishedPagesToMediaCollectionDialogOpen] =
    useState(false);
  const [isUpgradePlanDialogOpen, setIsUpgradePlanDialogOpen] = useState(false);

  const [selectedMediaCollections, setSelectedMediaCollections] = useState<CBOMediaCollection[]>([]);

  const [currentlyPlayedMediaCollection, setCurrentlyPlayedMediaCollection] =
    useState<PlayerMediaCollectionPreviewParams | null>(null);
  const [isPlayerMediaCollectionModalOpen, setIsPlayerMediaCollectionModalOpen] = useState<boolean>(false);

  const hasChanged = selectedMediaCollections.length > 0;

  // TODO: Don't fetch pages if they're already loaded
  useEffect(() => {
    getPages();
  }, [tenantId]);

  useEffect(() => {
    setSelectedMediaCollections([]);
  }, [isOpen]);

  useEffect(() => {
    if (mediaCollectionPagesUpdateStatus === 'success') handleCloseAddPublishedPagesToMediaCollectionDialog();
  }, [mediaCollectionPagesUpdateStatus]);

  const handleSelectMediaCollections = (mediaCollections: CBOMediaCollection[]) => {
    setSelectedMediaCollections(mediaCollections);
  };

  const handleClose = () => {
    onClose();
  };

  const handleSelect = () => {
    if (!currentShoppable) return;

    if (maxPublishedShoppablesCount) {
      const expectedPublishedShoppablesCount = getExpectedPublishedShoppablesCountAfterShoppableAdded(currentShoppable);

      if (expectedPublishedShoppablesCount > maxPublishedShoppablesCount) {
        setIsUpgradePlanDialogOpen(true);
        return;
      }
    }

    onSelectMediaCollections(selectedMediaCollections);
  };

  const handleDisplayUpdgradePlanDialog = () => {
    setIsUpgradePlanDialogOpen(true);
  };

  const handleCloseUpgradePlanDialog = () => {
    setIsUpgradePlanDialogOpen(false);
  };

  const handleFetchMediaCollection = (mediaCollectionId: string) => {
    if (currentMediaCollection?.id === mediaCollectionId) return;
    fetchOneMediaCollection(mediaCollectionId);
  };

  const handleCloseAddPublishedPagesToMediaCollectionDialog = () => {
    setIsAddPublishedPagesToMediaCollectionDialogOpen(false);
  };

  const handleOpenPublishedPagesEditor = (mediaCollectionId: string) => {
    handleFetchMediaCollection(mediaCollectionId);
    setIsAddPublishedPagesToMediaCollectionDialogOpen(true);
  };

  const handleMediaCollectionPreviewClick = ({ mediaCollectionId, startIndex }: PlayerMediaCollectionPreviewParams) => {
    setCurrentlyPlayedMediaCollection({ mediaCollectionId, startIndex });
    setIsPlayerMediaCollectionModalOpen(true);
  };

  const handleChangeIsInfiniteMediaCollectionEnabled = (
    mediaCollectionId: string,
    isInfiniteMediaCollectionEnabled: boolean,
  ) => {
    const mediaCollection = mediaCollections.find((mediaCollection) => mediaCollection.id === mediaCollectionId);

    if (mediaCollection) {
      updateMediaCollectionSettings(mediaCollectionId, {
        isInfiniteMediaCollectionEnabled,
        medias: mediaCollection.medias,
        title: mediaCollection.title,
      });
    }
  };

  const getExpectedPublishedShoppablesCountAfterShoppableAdded = useCallback(
    (currentShoppable: CBOShoppableVideoListItemReadModel) => {
      const mediaCollectionsWithShoppable: CBOMediaCollection[] = mediaCollections.map((mediaCollection) => {
        const isMediaCollectionSelected = selectedMediaCollections.some(({ id }) => id === mediaCollection.id);

        if (isMediaCollectionSelected) {
          return {
            ...mediaCollection,
            medias: [
              ...mediaCollection.medias,
              shoppableVideoListItemReadModelToMediaCollectionMedia(currentShoppable),
            ],
          };
        }

        return mediaCollection;
      });

      return countPublishedShoppablesFromMediaCollections(mediaCollectionsWithShoppable);
    },
    [mediaCollections.length, selectedMediaCollections.length],
  );

  const buttonsProps: ButtonProps[] = [
    {
      children: <span>{t('common:Cancel')}</span>,
      color: 'primary',
      onClick: handleClose,
      variant: 'outlined',
    },
    {
      children: <span>{selectedMediaCollections.length > 1 ? t('common:Add') : t('common:AddAndOpen')}</span>,
      color: 'primary',
      disabled: !hasChanged || mediaCollectionSettingsUpdateStatus === 'pending',
      onClick: handleSelect,
      variant: 'contained',
    },
  ];

  const displayedMediaCollections = currentShoppable?.id
    ? mediaCollections.filter((mediaCollection) => !mediaCollection.medias.some(({ id }) => id === currentShoppable.id))
    : [];

  return (
    <FormDialog
      DialogContentProps={{
        sx: {
          padding: 0,
          position: 'relative',
        },
      }}
      buttonsProps={buttonsProps}
      loading={false}
      onClose={handleClose}
      open={isOpen}
      sx={{
        minHeight: 500,
        minWidth: '600px',
        overflowY: 'visible',
      }}
      title={t('AddToMediaCollection')}
    >
      <Box
        sx={{
          height: '100%',
          // Used to fill parent
          position: 'absolute',
          width: '100%',
        }}
      >
        <MediaCollectionList
          canEnableInfiniteMediaCollection={canEnableInfiniteMediaCollection}
          mediaCollections={displayedMediaCollections}
          onChangeIsInfiniteMediaCollectionEnabled={handleChangeIsInfiniteMediaCollectionEnabled}
          onMediaCollectionPreviewClick={handleMediaCollectionPreviewClick}
          onOpenPublishedPagesEditor={handleOpenPublishedPagesEditor}
          onSelectMediaCollections={handleSelectMediaCollections}
          selectedMediaCollections={selectedMediaCollections}
        />
      </Box>

      <AddPublishedPagesToMediaCollectionDialog
        isOpen={isAddPublishedPagesToMediaCollectionDialogOpen}
        onClose={handleCloseAddPublishedPagesToMediaCollectionDialog}
        onDisplayUpdgradePlanDialog={handleDisplayUpdgradePlanDialog}
      />

      {maxPublishedShoppablesCount && (
        <UpgradePlanDialog
          isOpen={isUpgradePlanDialogOpen}
          maxPublishedShoppablesCount={maxPublishedShoppablesCount}
          onClose={handleCloseUpgradePlanDialog}
          tenantName={tenantName}
        />
      )}

      {!!currentlyPlayedMediaCollection?.mediaCollectionId && (
        <PlayerMediaCollectionPreviewDialog
          onClose={() => setIsPlayerMediaCollectionModalOpen(false)}
          open={isPlayerMediaCollectionModalOpen}
          {...currentlyPlayedMediaCollection}
        />
      )}
    </FormDialog>
  );
};

export default SelectMediaCollectionDialog;
