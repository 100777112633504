export default {
  AddANewProduct: 'Add a new Product',
  AddProduct: 'Add product',
  AdminMessageBackgroundColor: '"Moderator chat" message',
  AdminMessageIcon: 'Chat icon',
  Analytics: 'Analytics',
  BackToPrivateTest: 'Back to private test',
  BrandLogo: 'Brand Logo',
  Cancel: 'Cancel',
  CartIcon: 'Cart icon',
  ChangeCover: 'Change cover',
  ChatIntroductionText: 'Chat Introduction',
  Close: 'Close',
  Colors: 'Colors',
  Completed: 'Completed',
  ContributeOBS: 'Option 2: Stream from external streaming software',
  CopyEvent: 'Import from a past event',
  CopyEventConfirm: 'Do you really want to copy this event’s appearance ? The current theme assets will be overriden.',
  CopyEventId: 'Copy event id',
  Cover: 'Cover',
  Covers: 'Covers',
  CreateAnEvent: 'Create an event',
  CreateEvent: 'Create Event',
  CrossedPrice: 'Crossed price',
  CustomerInternalId: 'Your Internal Product ID',
  CustomerInternalIdIsRequired: 'Internal Product ID is required',
  CustomiseEvent: 'Event Customisation',
  Date: 'Date',
  DeleteEvent: 'Delete event {{title}}',
  DeleteThisEvent: 'Delete this event',
  DeleteThisProduct: 'Delete this product',
  Description: 'Description',
  DescriptionIsRequired: 'Description is required',
  DisplayActiveViewers: 'Display active viewers',
  DisplayChat: 'Display chat',
  DisplayEvent: 'Display event',
  DisplayLive: 'Display live',
  DisplayedProductHere: 'Displayed product will appear here',
  DownloadMP4: 'Download MP4',
  Downloads: 'Downloads',
  Duration: 'Duration',
  EditEvent: 'Edit Event',
  Event: 'Event',
  EventNotFound: 'Event not found',
  Events: 'Live Event Manager',
  FacebookRestreaming: {
    ExpiredTokenPleaseReconnect: 'Your facebook access has expired, please reconnect.',
    InitError: 'Your ad blocker might be blocking the Facebook login. Please disable it and refresh this page.',
    LinkAccount: 'Link account',
    RestreamingConfiguredForThisAccount: 'The stream will be multicasted on this account.',
    SelectAccount: 'Select account',
    SelectAccountForRestreaming: 'Select the account on which the stream will be multicasted.',
    TokenExpiresOn: 'Expires on {{date}}',
  },
  FieldRequiredIfStreamEnabled: 'This field is required when the multicast is enabled',
  FileSizeMustBeLowerThan: 'File size must be lower than',
  Finished: 'Finished',
  Fonts: 'Fonts',
  ImageURL: 'Image URL',
  InstagramRestreamingConfigurationExpired: 'Your Instagram streaming key has expired. Please input another one.',
  InstagramRestreamingIntroductionText:
    'Instagram streamkeys are valid for 5 hours only.\nInstagram automatically stops restreaming when you reach 60 min on IG Live.',
  LinkToEvent: 'Link to event',
  LinkToEventTooltip:
    'If you use our Live Shopping page, copy the link to a live event and share it with your audience.',
  LinkToReplay: 'Link to replay',
  LinkToReplayTooltip:
    'If you use our Live Shopping page, copy the link to a replay event and share it with your audience.',
  Live: 'Live manager',
  LivePageDisplay: 'Display on live shopping page',
  LiveReplay: 'Live Replay',
  LiveWithChat: 'Live manager',
  Logos: 'Logos',
  MagicContent: {
    AudioProcessing: 'Your Replay event audio is being processed. Please come back later or refresh this page.',
    AvailableFromDate: 'Magic content is available for all live events from {{date}}.',
    BetaFeature:
      'This feature is offered as part of the business and entreprise plans.\nGood news! it is available to all during Beta phase',
    Content: 'Content',
    Generate: 'Generate magic content',
    GenerateAgain: 'Generate magic content again',
    Intro:
      'Congratulations on your Live event!\n\nNow let’s invite more potential shoppers to watch your performance from the replay clip on your e-store. With our Magic Content feature, we generate an exciting written summary for this event. Simply share it via email, on your blog pages or any other means to invite more people to see your most recent show and drive more sales.',
    MagicContentFailed: 'Magic Content failed with error {{error}}',
    MagicContentPending: 'Magic content pending',
    MistakesDisclaimer: 'Magic Content can make mistakes. Check important info.',
    SnippetPreview: 'Snippet preview',
    Title: 'Title',
  },
  MainFontName: 'Font name',
  ModeratorMessageLabel: 'Moderator message label',
  NewEvent: 'Let’s set your event',
  NoEventsFound: 'No events found',
  NoPublisher: 'no publisher',
  NotScheduled: 'not scheduled',
  ObsPlanRestriction:
    'Only offered with the "Business" and "Enterprise" plans. Please upgrade your LiveMeUp plan to unlock this service.',
  Open: 'Open to public',
  OpenCameraApp: 'Option 1: Stream from LiveMeUp camera app',
  OpenCameraAppGivePhoneNumber: 'Use this phone number to log in:',
  OpenInstagramWarning:
    'When you’ll open this live to the public, you won’t be able to go back.\n\n<Bold>Don’t forget</Bold>: after this step you must go on Instagram and hit Go Live. Have a great Live show!',
  OpenReplay: 'Open replay to public',
  OpenWarning: 'When you’ll open this live to the public, you won’t be able to go back.',
  PhoneNumber: 'Phone Number',
  PhoneNumberNotValid: 'Phone number is not valid',
  PhoneNumberRequired: 'Phone number is required',
  PleaseEnterAValidURL: 'Please enter a valid URL',
  PostEventCover: 'Post Event Cover',
  PostEventText: 'Post event text',
  PreEventCover: 'Pre Event Cover',
  PreEventText: 'Pre event text',
  Preview: 'Preview',
  Price: 'Price',
  PriceCantBeNegative: "Price can't be < 0",
  PriceIsRequired: 'Price is required',
  PrimaryColor: '"Add to cart" button',
  ProductChapters: 'Product chapters',
  ProductHasManyVersions: 'This product has multiple options, like different sizes or colors',
  ProductImages: 'Images',
  ProductMainDescription: 'Main description',
  ProductOptions: 'Options',
  Products: 'Products',
  ProductsCount: 'Nb of products',
  ProductsLimitExceeded: 'Product limit exceeded',
  ProductsList: 'Products list',
  Publish: 'Private test',
  PublishReplay: 'Publish replay',
  Publisher: 'Publisher',
  PublisherPhone: 'Publisher Phone',
  Ready: 'Ready',
  Reminder: 'Reminder',
  Reminders: 'SMS reminders',
  ReplayInfos: 'Replay infos',
  Replays: 'Replay Manager',
  RestreamingHelp: 'For more informations, check our ',
  RestreamingHelpLinkText: 'Help & Support page.',
  SecondaryColor: '"Delete" button',
  Select: 'Select',
  SelectAPicture: 'Select a picture',
  ShortRTMPKeyValidityWarning:
    'Some services have short validity streamkeys. Please check the validity duration of the streamkey from your source platform.',
  Showcase: 'Showcase',
  ShowcaseDisplayedProduct: 'Displayed product',
  Showtime: 'Showtime',
  StartTime: 'Start time',
  Status: 'Status',
  StoreIcon: 'Store icon',
  Storehouse: 'Storehouse',
  StreamKey: 'Stream key',
  StreamUrl: 'Stream URL',
  Tabs: {
    Analytics: 'Analytics',
    LiveManager: 'Live Manager',
    MagicContents: 'AI Magic Content',
    Multistream: 'Multistream',
    Preview: 'Preview',
    Products: 'Products',
    ReplayLinks: 'Replay Links',
    Settings: 'Settings',
    Theme: 'Theme',
  },
  Title: 'Title',
  TitleIsRequired: 'Title is required',
  URL: 'URL',
  UnPublish: 'Stop test',
  Unplanned: 'Unplanned',
  UpdateProduct: 'Update product',
  UrlIsRequired: 'Url is required.',
  ValidateCartBackgroundColor: '"Proceed to checkout" button',
  VerticalImageOnly: '*Vertical image only',
  YoutubeRestreaming: {
    Disclosure:
      'LiveMeUp use and transfer to any other app of information received from Google APIs will adhere to <userDataPolicyLink>Google API Services User Data Policy</userDataPolicyLink>, including the Limited Use requirements.',
    EnableLiveStreaming: 'Enable live streaming',
    ErrorCloseTabAndRetry: 'The Youtube login could not be completed, please close this tab and try again.',
    ExpiredTokenPleaseReconnect: 'Your youtube access has expired, please reconnect.',
    FeatureMustBeEnabled: 'The live streaming feature must be enabled on your Youtube channel.',
    LinkAccount: 'Link account',
    NoChannels: 'You must have a Youtube channel in order to configure the multicast.',
    RestreamingConfiguredForThisChannel: 'The stream will be multicasted on this channel.',
    SelectChannel: 'Select channel',
    SelectChannelForRestreaming: 'Select the channel on which the stream will be multicasted.',
    SuccessCloseTab: 'You can close this tab and continue configuring the multicast.',
    TokenExpiresOn: 'Expires on {{date}}',
    YoutubeLiveStreamingNotEnabled:
      'It seems that the live streaming feature is not enabled on your Youtube channel.\n\nPlease activate it <enableFeatureLink>here</enableFeatureLink> and retry when Youtube has authorized this feature. Note that it can take up to 24 hours.',
    YoutubeLogin: 'Youtube login',
    YoutubeLoginFailed: 'Youtube login unsuccessful',
    YoutubeLoginSuccessful: 'Youtube login successful',
  },
  counters: {
    ActiveChatUsers: 'Active chat users',
    ActiveChatUsersHelper: 'Number of viewers having sent at least one message to the chat',
    ActiveViewers: 'Active viewers',
    ActiveViewersHelper: 'Active viewers',
    CartQuantity: 'Products in Cart',
    CartQuantityHelper: 'Number of product actually in a cart',
    Likes: 'Likes',
    LikesHelper: 'Number of times the Like button has been clicked',
    LiveCartAmount: 'Amount in cart',
    LiveCartAmountHelper: 'Sum of all products prices actually in cart',
    LivePurchasedAmount: 'Purchased amount',
    LivePurchasedAmountHelper: 'Sum of all purchased products',
    ProductInCart: 'Cart',
    ProductInCartHelper: 'Number of products in a cart',
    ProductPageOpened: 'Product page opened',
    ProductPageOpenedHelper: 'Product page opened',
    ProductViews: 'Views',
    ProductViewsHelper: 'Product page opened',
    TotalUniqueViewers: 'Unique viewers',
    TotalUniqueViewersHelper: 'Number of unique viewers who started the live',
    ValidateCart: 'Cart validated',
    ValidateCartHelper: 'Number of times a user clicked on validate cart',
  },
  fonts: {
    ChooseAFont: 'Choose font',
    MainFont: 'Main font',
    NewLabel: 'New font',
  },
  helpers: {
    ChatIcon:
      'Personnalize your chat messages sent out from Live Manager console as you communicate with your audience',
    ChatIntroductionText:
      'This is the welcome text that your audience will see when they first join the chat as a participant.',
    Colors: 'You can select up to 4 colors to customize the User Interface',
    Description: 'Share additional information about the event here.',
    Font: 'Select your brand’s font that will be loaded in our video player',
    LiveHeaderLogo: 'Upload your logo that will show up in the top left corner of the Live and Replay Clips',
    ModeratorMessageLabel: 'This is your brand alias that will be displayed when you text the audience in the chat.',
    PostEventCover: 'Vertical Image only: Recommended Image dimension : 1080 x 1920. Image size Max 300kb',
    PostEventText: 'This text will be overlaid on your post event cover when you stop your Live streaming session.',
    PreEventCover: 'Vertical Image only: Recommended Image dimension : 1080 x 1920. Image size Max 300kb',
    PreEventText:
      'This text will be overlaid on your event cover together with the countdown before you open streaming to public.',
    ProductDescription:
      'Change here the product description. The change will only be reflected in your Live Shopping player.',
    ProductTitle: 'Change here the product title. The change will only be reflected in your Live Shopping player.',
    ReplayTitle: 'The title appears as an overlay on the video feed of your Replay event.',
    Title: 'The title will appear as an overlay on the video feed of your Live and Replay events.',
  },
  notifications: {
    ActiveSubscriptionRequired: 'You must have subscribed to a plan in order to go live',
    AddProductsToEventFailed: 'An error occurred while adding products to the event. Please retry.',
    AddProductsToEventSuccess: 'Products successfully added to the event.',
    EventFailedClosing: 'An error occurred while closing the event. Please retry.',
    EventFailedCreating: 'An error occurred while creating the event. Please retry.',
    EventFailedDeleting: 'An error occurred while deleting the event. Please retry.',
    EventFailedOpening: 'An error occurred while opening the event. Please retry.',
    EventFailedPublishing: 'An error occurred while publishing the event. Please retry.',
    EventFailedUnPublishing: 'An error occurred while un-publishing the event. Please retry.',
    EventFailedUpdating: 'An error occurred while updating the event. Please retry.',
    EventSuccessfullyClosed: 'Event successfully closed!',
    EventSuccessfullyCreated: 'Event successfully created!',
    EventSuccessfullyDeleted: 'Event successfully deleted!',
    EventSuccessfullyFetched: 'Events successfully retrieved!',
    EventSuccessfullyOpened: 'Event successfully opened!',
    EventSuccessfullyPublished: 'Event successfully published!',
    EventSuccessfullyUnPublished: 'Event successfully un-published!',
    EventSuccessfullyUpdated: 'Event successfully updated!',
    EventThemeFailedCopying: 'An error occurred while copying the event. Please retry.',
    EventThemeFailedUpdating: 'An error occurred while updating the event. Please retry.',
    EventThemeSuccessfullyCopied: 'Event successfully copied!',
    EventThemeSuccessfullyUpdating: 'Event successfully customised!',
    EventsFailedFetching: 'An error occurred while fetching events. Please retry.',
    ExpiredFacebookToken:
      'Your facebook access has expired. Please reconnect your account in the "Multicast" event settings section.',
    FacebookFailedConnecting: 'An error occurred while connecting to Facebook. Please retry.',
    FrozenEvent: 'You can not update an event that is in Private test. Please stop your test and start editing.',
    MessageFailedPinning: 'An error occurred while pinning the message. Please retry.!',
    MessageFailedUnpinning: 'An error occurred while unpinning the message. Please retry.!',
    MessageSuccessfullyPinned: 'Message successfully pinned!',
    MessageSuccessfullyUnpinned: 'Message successfully unpinned!',
    NotStreaming: 'Your LMU camera app must be on in order to go live.',
    ProductFailedAddition: 'An error occurred while adding the product. Please retry.',
    ProductFailedDeleting: 'An error occurred while deleting the product. Please retry.',
    ProductFailedUpdating: 'An error occurred while updating the product. Please retry.',
    ProductSuccessfullyAdded: 'Product successfully added!',
    ProductSuccessfullyDeleted: 'Product successfully removed!',
    ProductSuccessfullyUpdated: 'Product successfully updated!',
    ProductsLineupFailedReordering: 'An error occurred while reordering the product lineup. Please retry.',
    ProductsLineupSuccessfullyReordered: 'Products lineup successfully reordered!',
    PublisherPhoneRequired: 'The publisher’s phone number must be set.',
    ReplayFailedUpdating: 'An error occurred while updating the replay. Please retry.',
    ReplaySuccessfullyUpdated: 'Replay successfully updated!',
    StreamKeySuccessfullyCopied: 'Stream key successfully copied to your clipboard!',
    StreamUrlSuccessfullyCopied: 'Stream URL successfully copied to your clipboard!',
    StreamingRequiredForRestreaming: 'Your LMU camera app must be on in order to restream.',
  },
  productDetails: {
    Details: 'Details',
    MainDescription: 'Main description',
    Pictures: 'Pictures',
    Preview: 'Preview',
  },
  settings: {
    Chat: 'Chat Settings',
    Covers: 'Covers',
    Event: 'Event settings',
    Pictures: 'Pictures',
    PreAndPostEventOverlays: 'Pre and Post event overlays',
    Stream: 'Camera / Stream source',
  },
  status: {
    Draft: 'Draft',
    OffAir: 'Off air',
    OnAir: 'On air',
    Planned: 'Planned',
    ProcessingReplay: 'Processing replay',
    ReplayFailed: 'Replay failed',
    ReplayOnAir: 'Replay',
    ReplayReady: 'Replay ready',
    Test: 'Private test',
    TestReplay: 'Private replay',
  },
  themePreview: {
    addToCart: 'Add to cart',
    chatModerator: 'Moderator',
    chatUser: 'User',
    joinChat: 'Join chat',
    live: 'Live',
    messageContent: 'This is a demo text.',
    productTitle: 'My product',
  },
};
