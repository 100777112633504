import { CBOCatalogProduct } from '../core/domain/CBOCatalogProduct';
import { CBOEventReadModelProduct } from '../core/domain/CBOEventReadModelProduct';

export const getProductQuantity = (product: CBOCatalogProduct | CBOEventReadModelProduct): number | null => {
  const { inventoryQuantity, inventoryPolicy } = product;

  if (inventoryQuantity) {
    return inventoryQuantity;
  }

  if (inventoryPolicy === 'deny') {
    return 0;
  }

  // if null, it means that the product is out of stock but it is still possible to buy it (shopify option on product editor)
  return null;
};
