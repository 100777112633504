import { Instagram } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ENDPOINTS } from '../../../../../../endpoints';
import RTMPRestreamingSection, { RestreamingProps, RestreamingSectionProps } from './RTMPRestreamingSection';

const InstagramRestreamingSection: FC<RestreamingSectionProps> = ({
  canEnableMultistream,
  keyError,
  urlError,
  ...props
}) => {
  const { t } = useTranslation(['events', 'common']);

  const instagramRestreamingProps: RestreamingProps = useMemo(
    () => ({
      helpLink: ENDPOINTS.restreaming.instagramRestreamingHelp,
      id: 'instagram',
      introductionText: t('events:InstagramRestreamingIntroductionText'),
      keyError,
      logo: (
        <Avatar
          sx={{
            bgcolor: (theme) => (canEnableMultistream ? theme.palette.socials.instagram : theme.palette.grey[500]),
          }}
        >
          <Instagram />
        </Avatar>
      ),
      title: t('common:Socials.Instagram'),
      urlError,
    }),
    [canEnableMultistream, keyError, t, urlError],
  );

  return (
    <RTMPRestreamingSection
      {...props}
      canEnableMultistream={canEnableMultistream}
      restreamingProps={instagramRestreamingProps}
    />
  );
};

export default InstagramRestreamingSection;
