import { ContentCopy } from '@mui/icons-material';
import { Box, Button, Paper, TextField, Typography, styled } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import IntegrationsHeader from '../../../atoms/integrations/IntegrationsHeader';
import IntegrationDisabled from '../../../molecules/integrations/IntegrationDisabled';
import { createNappsIntegrationViewModel } from './NappsIntegration.viewmodel';

const Container = styled(Box)({
  width: '100%',
});

const ContentContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  margin: 'auto',
  marginTop: theme.spacing(2),
  width: '100%',
}));

const InputAndButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
}));

const Section = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
  textAlign: 'initial',
}));

const SectionTitle = styled(Typography)({
  fontSize: '1.25em',
  fontWeight: 'bolder',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '15px',
  [theme.breakpoints.up('xs')]: {
    width: '95%',
  },
  [theme.breakpoints.up('md')]: {
    width: '60%',
  },
}));

const Text = styled(Typography)({
  fontSize: '.95em',
});

export default function NappsIntegration() {
  const dispatch = useDispatch();
  const viewModel = useSelector(createNappsIntegrationViewModel({ dispatch }));
  const { t } = useTranslation(['integrations', 'common']);

  const { apiKey, currentTenant, isEnabled, trackUserJourneyEvent } = viewModel;

  useEffect(() => {
    trackUserJourneyEvent({
      data: { 'Tenant id': currentTenant.id, 'Tenant name': currentTenant.name },
      name: 'Napps integration',
    });
  }, []);

  const handleCopyApiKey = () => {
    navigator.clipboard.writeText(apiKey);
  };

  return (
    <Container>
      <IntegrationsHeader title={t('Napps.Napps')} />
      <ContentContainer>
        <StyledPaper>
          {!isEnabled ? (
            <IntegrationDisabled tenantName={currentTenant.name} text={t('Napps.IntegrationNotEnabled')} />
          ) : (
            <Section>
              <SectionTitle mb={1} variant="h6">
                {t('Napps.Title')}
              </SectionTitle>
              <Box display="flex" flexDirection="column" gap={3}>
                <Text>{t('Napps.Description')}</Text>
                <InputAndButtonContainer>
                  <TextField
                    InputProps={{ style: { fontFamily: 'monospace' } }}
                    disabled
                    fullWidth
                    label={t('Napps.ApiKey')}
                    size="small"
                    value={apiKey}
                  />
                  <Button color="primary" onClick={handleCopyApiKey} startIcon={<ContentCopy />} variant="contained">
                    <span>{t('common:Copy')}</span>
                  </Button>
                </InputAndButtonContainer>
              </Box>
            </Section>
          )}
        </StyledPaper>
      </ContentContainer>
    </Container>
  );
}
