import { V1 } from '@bellepoque/api-contracts';
import { Visibility, VisibilityOff, WarningRounded } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Link,
  Switch,
  TextField,
  Typography,
  accordionClasses,
  accordionSummaryClasses,
  styled,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InputTooltip from '../../../../atoms/inputs/InputTooltip';

const TitleContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'initial',
});

const HelpMessageContainer = styled(Box)({
  display: 'block',
  justifyContent: 'flex-start',
  width: '100%',
});

const AccordionRoot = styled(Accordion)(({ theme }) => ({
  '&::before': {
    display: 'none',
  },
  backgroundColor: 'transparent',

  [`&.${accordionClasses.expanded}`]: {
    margin: 0,

    [`& .${accordionSummaryClasses.content}`]: {
      margin: '12px 0',
    },
  },

  [`& .${accordionSummaryClasses.root}`]: {
    padding: `0 ${theme.spacing(3)}`,
  },
}));

export type RestreamingErrorsProps = {
  keyError?: string;
  urlError?: string;
};

export type RestreamingProps = RestreamingErrorsProps & {
  helpLink?: string;
  id: string;
  introductionText?: string;
  keyTooltip?: string;
  logo?: JSX.Element;
  title: string;
  urlTooltip?: string;
};

export type RTMPRestreamingSectionProps = {
  canEnableMultistream: boolean;
  expiresAt: Date | null;
  introductionText?: string;
  onChangeRestreamingEnabled: (value: boolean) => void;
  readonly: boolean;
  restreamingEnabledWatch: boolean;
  restreamingKeyInputProps: any;
  restreamingKeyInputRef: React.Ref<any>;
  restreamingProps: RestreamingProps;
  restreamingUrlInputProps: any;
  restreamingUrlInputRef: React.Ref<any>;
  submissionInProgress: boolean;
  type:
    | typeof V1.api.RestreamingPlatformSchema.enum.Instagram
    | typeof V1.api.RestreamingPlatformSchema.enum.Other_RTMP_Source;
};

export type RestreamingSectionProps = Omit<RTMPRestreamingSectionProps, 'restreamingProps'> & RestreamingErrorsProps;

const RTMPRestreamingSection: FC<RTMPRestreamingSectionProps> = ({
  canEnableMultistream,
  expiresAt,
  onChangeRestreamingEnabled,
  readonly,
  restreamingEnabledWatch,
  restreamingKeyInputProps,
  restreamingKeyInputRef,
  restreamingProps,
  restreamingUrlInputProps,
  restreamingUrlInputRef,
  submissionInProgress,
}) => {
  const { t } = useTranslation(['events', 'common']);
  const [isStreamKeyShown, setIsStreamKeyShown] = useState(false);
  const hasExpired = restreamingEnabledWatch && expiresAt && expiresAt < new Date();

  return (
    <AccordionRoot disabled={readonly} elevation={0} expanded={restreamingEnabledWatch}>
      <AccordionSummary sx={{ cursor: 'text' }}>
        <Box alignItems="center" display="flex" flex={1} justifyContent="space-between">
          <Box alignItems="center" display="flex" gap={2}>
            {restreamingProps.logo}
            <TitleContainer>
              <Box alignItems="center" display="flex" gap="8px">
                <Typography color={readonly ? 'gray' : 'black'} fontSize="1em" fontWeight="bold" variant="titleFont">
                  {restreamingProps.title}
                </Typography>
              </Box>

              {!!restreamingProps.helpLink && (
                <HelpMessageContainer>
                  <Typography fontSize=".8em">
                    {t('RestreamingHelp')}
                    <Link href={restreamingProps.helpLink} target="_blank">
                      {t('RestreamingHelpLinkText')}
                    </Link>
                  </Typography>
                </HelpMessageContainer>
              )}
            </TitleContainer>
          </Box>
          <Box alignItems="center" display="flex">
            <Switch
              checked={restreamingEnabledWatch}
              disabled={!canEnableMultistream || submissionInProgress || readonly}
              onChange={(_event, val) => onChangeRestreamingEnabled(val)}
            />
            {hasExpired && <WarningRounded color="error" cursor="initial" />}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {!!restreamingProps.introductionText && (
          <Typography fontSize=".8em" whiteSpace="pre-line">
            {restreamingProps.introductionText}
          </Typography>
        )}
        <Grid item position="relative" xs={12}>
          <TextField
            disabled={submissionInProgress || readonly}
            error={!!restreamingProps.urlError}
            fullWidth
            helperText={restreamingProps.urlError}
            id={`${restreamingProps.id}-restreaming-url`}
            inputRef={restreamingUrlInputRef}
            label={t('StreamUrl')}
            variant="filled"
            {...restreamingUrlInputProps}
          />
          {!!restreamingProps.urlTooltip && <InputTooltip content={restreamingProps.urlTooltip} />}
        </Grid>
        <Grid item position="relative" xs={12}>
          <TextField
            InputProps={{
              endAdornment: (
                <IconButton disabled={readonly} onClick={() => setIsStreamKeyShown(!isStreamKeyShown)}>
                  {isStreamKeyShown ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
            }}
            disabled={submissionInProgress || readonly}
            error={!!restreamingProps.keyError}
            fullWidth
            helperText={restreamingProps.keyError}
            id={`${restreamingProps.id}-restreaming-key`}
            inputRef={restreamingKeyInputRef}
            label={t('StreamKey')}
            type={isStreamKeyShown ? 'text' : 'password'}
            variant="filled"
            {...restreamingKeyInputProps}
          />
          {hasExpired && (
            <Typography color="error" fontSize=".8em" mt={1}>
              {t('InstagramRestreamingConfigurationExpired')}
            </Typography>
          )}
          {!!restreamingProps.keyTooltip && <InputTooltip content={restreamingProps.keyTooltip} />}
        </Grid>
      </AccordionDetails>
    </AccordionRoot>
  );
};

export default RTMPRestreamingSection;
