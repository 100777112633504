export default {
  AddAnOption: 'Add an option',
  AllVariantsHaveSamePrices: 'All variants have same prices',
  DeleteOption: 'Delete option',
  Option: 'Option',
  Option_number: 'Option {{number}}',
  Preview: 'Preview',
  SelectYourProducts: 'Select your products',
  SelectedProducts_one: 'Selected product',
  SelectedProducts_other: 'Selected products',
  ThisVariantWasNotCreated: 'This variant was not created',
  ThisVariantWillNotBeCreated: 'This variant will not be created',
  VariantId: 'Variant ID',
  Variants: 'Variants',
  Versions: 'Versions',
  color: 'color',
  color_capitalized: 'Color',
  colors: 'colors',
  material: 'material',
  material_capitalized: 'Material',
  materials: 'materials',
  options: {
    AllAreSelected: 'All values are selected',
    CanAddNewVariant: 'You can add {{a_new}} value by writing its name and pressing enter.',
    ChooseSomeVariant: 'Select a value',
    NewLabel: 'New value',
    NewValueNotValid: 'This value is not valid or already exists',
  },
  quantity: {
    Available: 'Available',
    AvailableWithQuantity: '{{quantity}} available',
    LastOne: 'One left',
    Unavailable: 'Out of stock',
  },
  size: 'size',
  size_capitalized: 'Size',
  sizes: 'sizes',
  style: 'style',
  style_capitalized: 'Style',
  styles: 'styles',
  title: 'title',
  title_capitalized: 'Title',
  titles: 'titles',
};
