export default {
  Catalog: {
    AddToEvent: 'Add to...',
    AddToEventDialog: {
      AlreadyAdded: 'Already added',
      NoEvents: 'No events were created.',
      Text: 'Choose the event to which the product will be added.',
      Text_other: 'Choose the event to which the products will be added.',
      Title: 'Add this product to...',
      Title_other: 'Add these products to...',
    },
    ApiKey: 'API key',
    Catalog: 'Catalog',
    ClearSelection: 'Clear selection',
    NoProducts: 'No product was found.',
    RenewApiKey: 'Renew',
    SelectedItems: '{{count}} selected product',
    SelectedItems_other: '{{count}} selected products',
    notifications: {
      CatalogProductsFailedDeleting: 'An error occurred while deleting the products. Please retry.',
      CatalogProductsSuccessfullyDeleted: 'Products successfully deleted !.',
    },
  },
  CmsRequired: 'CMS is required',
  CountryCodeRequired: 'Country code is required',
  CreateTenant: 'Create',
  EmailValidationError: 'Please enter a valid email address',
  EventFinder: 'Event finder',
  EventId: 'Event id',
  InviteMaster: 'Invite',
  MasterEmail: 'Your team member email',
  MasterEmailRequired: 'Master email is required',
  MasterInvitation: 'Add Team Members',
  Name: 'Name',
  RenameTenant: 'Rename tenant',
  TenantName: 'Store',
  TenantNameRequired: 'Tenant name is required',
  Tools: 'Tools',
  notifications: {
    AliasFailedUpdating: 'An error occurred while updating the tenant alias. Please retry.',
    AliasSuccessfullyUpdated: 'Tenant alias successfully updated!',
    IntegrationsFailedUpdating: 'An error occurred while updating the integrations. Please retry.',
    IntegrationsSuccessfullyUpdated: 'Integrations successfully updated!',
  },
};
