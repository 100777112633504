import { V1 } from '@bellepoque/api-contracts';
import { Delete, Info } from '@mui/icons-material';
import PlayIcon from '@mui/icons-material/PlayArrow';
import { Box, Button, IconButton, Switch, Tooltip, Typography, styled } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel, GridValueGetterParams } from '@mui/x-data-grid';
import React, { FC, Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOMediaCollection } from '../../../../core/domain/CBOMediaCollection';
import { CBOMediaCollectionMedia } from '../../../../core/domain/CBOMediaCollectionMedia';
import { CBOMediaCollectionPublishedPage } from '../../../../core/domain/CBOMediaCollectionPublishedPage';
import CreateButton from '../../atoms/CreateButton';
import CustomLoadingGridOverlay from '../../atoms/CustomLoadingGridOverlay';
import MediaCollectionStatusChip from '../../atoms/media-collections/MediaCollectionStatusChip';
import { PlayerMediaCollectionPreviewParams } from '../PlayerMediaCollectionPreviewDialog';

const maxMediasCount = V1.api.constraints.mediaCollections.maxMediasCount;

const DeleteIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.black,
}));

//TODO: Use theme spacing and colors
const MediaCollectionListRoot = styled(DataGrid)(({ theme }) => ({
  [`.MuiDataGrid-columnHeaderTitleContainer`]: {
    justifyContent: 'center',
    paddingLeft: 0,
  },
  [`.MuiDataGrid-columnSeparator`]: {
    visibility: 'hidden',
  },
  [`.MuiDataGrid-columnsContainer`]: {
    background: theme.palette.background.default,
    border: 'none',
  },

  border: 'none',
  fontFamily: theme.typography.h1.fontFamily,
  height: '100%',
  [`& .${classes.gridRow} > .${classes.cell}`]: {
    border: 'none',
    cursor: 'default',
    outline: 'none',
    padding: `0 ${theme.spacing(2)}`,
  },
  [`& .${classes.boldCell}`]: {
    fontWeight: 'bold',
  },
  [`& .${classes.gridRow}`]: {
    '&:nth-child(2n)': {
      background: theme.palette.background.default,
    },
    '&:nth-child(2n-1)': {
      background: theme.palette.common.white,
    },
    cursor: 'pointer',
  },
  [`& .${classes.mediumCell}`]: {
    fontWeight: 500,
  },
  [`& .${classes.hidden}`]: {
    display: 'none',
  },
}));

const MediaCollectionListButtonsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  gap: theme.spacing(1),
  justifyContent: 'end',
  lineHeight: 'initial',
  marginRight: theme.spacing(1),
}));

// TODO: use theme spacings
const MediaCollectionListLocationsContainer = styled(Box)({
  alignItems: 'start',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  height: '100%',
  justifyContent: 'center',
  textDecoration: 'underline',
});

const MediaCollectionListVideoItemsContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '4px',
  height: '100%',
  overflow: 'auto',
  padding: '8px 0',
  textAlign: 'left',
  width: '100%',
});

const MediaCollectionListVideoItem = styled(Box, { shouldForwardProp: (prop) => prop !== 'thumbnailUrl' })<{
  thumbnailUrl: string | null;
}>(({ theme, thumbnailUrl }) => ({
  backgroundColor: theme.palette.info.main,
  backgroundImage: `url(${thumbnailUrl ?? ''})`, // TODO: Placeholder
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  borderRadius: '50%',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  height: '50px',
  justifyContent: 'end',
  minWidth: '50px',
  position: 'relative',
  width: '50px',
}));

const MediaCollectionListStatusContainer = styled(Box)({
  alignItems: 'start',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

const MediaCollectionListStatus = styled(Box)({ alignItems: 'center', display: 'flex', gap: '4px' });

// TODO: use theme
const PlayIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.35)',
  },
  background: 'rgba(0, 0, 0, 0.2)',
  color: theme.palette.common.white,
  height: '28px',
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',

  width: '28px',
}));

const MediaCollectionSwitchButtonContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const MediaCollectionSwitchButtonErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '8px',
  fontWeight: 'bold',
}));

const MediaCollectionInfiniteMediaCollectionHeaderContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  gap: '4px',
});

const PREFIX = 'MediaCollectionList';

const classes = {
  boldCell: `${PREFIX}-bold-cell`,
  cell: `${PREFIX}-cell`,
  columnHeader: `${PREFIX}-column-header`,
  gridRow: `${PREFIX}-grid-row`,
  hidden: `${PREFIX}-hidden`,
  mediumCell: `${PREFIX}-medium-cell`,
};

export type MediaCollectionListProps = {
  canEnableInfiniteMediaCollection: boolean;
  loading?: boolean;
  mediaCollections: CBOMediaCollection[];
  onChangeIsInfiniteMediaCollectionEnabled: (
    mediaCollectionId: string,
    isInfiniteMediaCollectionEnabled: boolean,
  ) => void;
  onDeleteMediaCollectionClick?: (mediaCollectionId: string) => void;
  onGoToMediaCollectionEditor?: (mediaCollectionId?: string) => void;
  onMediaCollectionPreviewClick: ({ mediaCollectionId, startIndex }: PlayerMediaCollectionPreviewParams) => void;
  onOpenPublishedPagesEditor: (collectionId: string) => void;
  onSelectMediaCollections?: (mediaCollections: CBOMediaCollection[]) => void;
  selectedMediaCollections?: CBOMediaCollection[];
};

const MediaCollectionList: FC<MediaCollectionListProps> = ({
  canEnableInfiniteMediaCollection,
  loading = false,
  mediaCollections,
  onChangeIsInfiniteMediaCollectionEnabled,
  onDeleteMediaCollectionClick,
  onGoToMediaCollectionEditor,
  onMediaCollectionPreviewClick,
  onOpenPublishedPagesEditor,
  onSelectMediaCollections,
  selectedMediaCollections = [],
}) => {
  const { t } = useTranslation(['mediaCollections', 'common']);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'title',
      sort: 'asc',
    },
  ]);

  const handleSelectMediaCollection = useCallback(
    (mediaCollection: CBOMediaCollection) => (event: any, isChecked: boolean) => {
      const newSelectedMediaCollections = isChecked
        ? [...selectedMediaCollections, mediaCollection]
        : selectedMediaCollections.filter(
            (selectedMediaCollection) => selectedMediaCollection.id !== mediaCollection.id,
          );

      onSelectMediaCollections?.(newSelectedMediaCollections);
    },
    [onSelectMediaCollections, selectedMediaCollections],
  );

  const handleClickEditMediaCollection = (mediaCollectionId: string) => () =>
    onGoToMediaCollectionEditor?.(mediaCollectionId);

  const handleClickOpenPublishedPagesEditor = (mediaCollectionId: string) => () => {
    onOpenPublishedPagesEditor(mediaCollectionId);
  };

  const handleMediaCollectionPreviewClick =
    ({ mediaCollectionId, startIndex }: PlayerMediaCollectionPreviewParams) =>
    () => {
      onMediaCollectionPreviewClick({ mediaCollectionId, startIndex });
    };

  const renderMediaCollectionPublishedPagesCell = useCallback(
    ({ row }: GridRenderCellParams<any, CBOMediaCollection>) => {
      if (row.publishedPages.length === 0)
        return (
          <Typography
            color="secondary"
            onClick={handleClickOpenPublishedPagesEditor(row.id)}
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            {t('SetDestination')}
          </Typography>
        );

      const selectedPublishedPages = row.publishedPages.reduce(
        (
          {
            hasHomepage,
            productPagesNumber,
            collectionPagesNumber,
          }: {
            collectionPagesNumber: number;
            hasHomepage: boolean;
            productPagesNumber: number;
          },
          selectedPublishedPage: CBOMediaCollectionPublishedPage,
        ) => {
          if (selectedPublishedPage.type === 'home') hasHomepage = true;
          else if (selectedPublishedPage.type === 'product') {
            productPagesNumber = productPagesNumber + 1;
          } else {
            collectionPagesNumber = collectionPagesNumber + 1;
          }

          return { collectionPagesNumber, hasHomepage, productPagesNumber };
        },
        { collectionPagesNumber: 0, hasHomepage: false, productPagesNumber: 0 },
      );

      return (
        <MediaCollectionListLocationsContainer onClick={handleClickOpenPublishedPagesEditor(row.id)}>
          {selectedPublishedPages.hasHomepage && <Typography>{t('HomePage')}</Typography>}
          {selectedPublishedPages.productPagesNumber > 0 && (
            <Typography>
              <Typography>{t('ProductPage', { count: selectedPublishedPages.productPagesNumber })}</Typography>
            </Typography>
          )}
          {selectedPublishedPages.collectionPagesNumber > 0 && (
            <Typography>{t('CollectionPage', { count: selectedPublishedPages.collectionPagesNumber })}</Typography>
          )}
        </MediaCollectionListLocationsContainer>
      );
    },
    [],
  );

  const renderActionsCell = useCallback(({ row }: GridRenderCellParams<any, CBOMediaCollection>) => {
    const isDeleteDisabled = row.publishedPages.length > 0;

    return (
      <Box alignItems="center" display="flex" gap={0} minWidth={0}>
        <Tooltip
          disableInteractive
          title={isDeleteDisabled ? (t('MediaCollectionPublishedCannotRemove') as string) : ''}
        >
          {/* 
              In order to show the tooltip when the button is disabled (removes pointer-events), we wrap it in a Box
              inline-flex removes a gap when using a Box as a child of Tooltip
            */}
          <Box display="inline-flex">
            <DeleteIconButton disabled={isDeleteDisabled} onClick={() => onDeleteMediaCollectionClick?.(row.id)}>
              <Delete fontSize="small" />
            </DeleteIconButton>
          </Box>
        </Tooltip>
      </Box>
    );
  }, []);

  const renderCollectionContentCell = useCallback(({ row }: GridRenderCellParams<any, CBOMediaCollection>) => {
    const renderMedia = (media: CBOMediaCollectionMedia, index: number) => (
      <MediaCollectionListVideoItem key={media.id} thumbnailUrl={media.image}>
        <Fragment>
          <PlayIconButton onClick={handleMediaCollectionPreviewClick({ mediaCollectionId: row.id, startIndex: index })}>
            <PlayIcon />
          </PlayIconButton>
        </Fragment>
      </MediaCollectionListVideoItem>
    );

    return (
      <MediaCollectionListVideoItemsContainer>{row.medias.map(renderMedia)}</MediaCollectionListVideoItemsContainer>
    );
  }, []);

  const renderMediaCollectionStatusCell = useCallback(({ value }: GridRenderCellParams<string, CBOMediaCollection>) => {
    return (
      <MediaCollectionListStatusContainer>
        <MediaCollectionListStatus>
          <MediaCollectionStatusChip isDisplayed={value === t('Displayed')} label={value} />
        </MediaCollectionListStatus>
      </MediaCollectionListStatusContainer>
    );
  }, []);

  const renderMediaCollectionInfiniteMediaCollectionCell = ({
    row,
    value,
  }: GridRenderCellParams<boolean, CBOMediaCollection>) => (
    <Switch
      checked={value}
      onChange={(_event, checked) => onChangeIsInfiniteMediaCollectionEnabled(row.id, checked)}
      size="small"
    />
  );

  const renderMediaCollectionActionsCell = ({ row }: GridRenderCellParams<any, CBOMediaCollection>) => (
    <MediaCollectionListButtonsContainer>
      {onGoToMediaCollectionEditor && (
        <Button onClick={handleClickEditMediaCollection(row.id)} variant="outlined">
          {t('common:Edit')}
        </Button>
      )}
    </MediaCollectionListButtonsContainer>
  );

  const renderMediaCollectionSwitchCell = useCallback(
    ({ row }: GridRenderCellParams<any, CBOMediaCollection>) => {
      const isMaxMediaCollectionMediasReached = row.medias.length === maxMediasCount;

      const isSelected = selectedMediaCollections.some(({ id }) => id === row.id);

      const isDisabled = isMaxMediaCollectionMediasReached;

      return (
        <MediaCollectionSwitchButtonContainer>
          <Switch checked={isSelected} disabled={isDisabled} onChange={handleSelectMediaCollection(row)} />
          {isMaxMediaCollectionMediasReached && (
            <MediaCollectionSwitchButtonErrorText>
              {t('MaxMediasReached', {
                count: row.medias.length,
                maxCount: maxMediasCount,
              })}
            </MediaCollectionSwitchButtonErrorText>
          )}
        </MediaCollectionSwitchButtonContainer>
      );
    },
    [handleSelectMediaCollection, selectedMediaCollections],
  );

  const renderMediaCollectionInfiniteMediaCollectionHeader = () => (
    <MediaCollectionInfiniteMediaCollectionHeaderContainer>
      {t('mediaCollections:InfinitePlaylistSwichLabel')}
      <Tooltip title={t('mediaCollections:InfinitePlaylistSwichTooltip').toString()}>
        <Info sx={{ fontSize: '20px' }} />
      </Tooltip>
    </MediaCollectionInfiniteMediaCollectionHeaderContainer>
  );

  const defaultHeaderProps: Partial<GridColDef> = {
    flex: 1,
    headerClassName: classes.columnHeader,
    sortable: true,
  };

  const columns: GridColDef[] = [
    {
      ...defaultHeaderProps,
      field: 'checkbox',
      headerName: '',
      hide: !onSelectMediaCollections,
      renderCell: renderMediaCollectionSwitchCell,
    },
    {
      ...defaultHeaderProps,
      field: 'actions',
      flex: 0,
      headerName: '',
      hide: !!onSelectMediaCollections,
      minWidth: 0,
      renderCell: renderActionsCell,
      width: 70,
    },
    {
      ...defaultHeaderProps,
      cellClassName: classes.boldCell,
      field: 'title',
      flex: 1.5,
      headerName: t('Name'),
    },
    {
      ...defaultHeaderProps,
      cellClassName: classes.boldCell,
      field: 'medias',
      flex: 2,
      headerName: t('Content'),
      renderCell: renderCollectionContentCell,
      sortable: false,
    },
    {
      ...defaultHeaderProps,
      align: 'center',
      cellClassName: classes.mediumCell,
      field: 'publishedPages',
      headerName: t('Destinations'),
      renderCell: renderMediaCollectionPublishedPagesCell,
      sortable: false,
    },
    {
      ...defaultHeaderProps,
      align: 'center',
      cellClassName: classes.boldCell,
      field: 'status',
      headerName: t('Status'),
      renderCell: renderMediaCollectionStatusCell,
      sortComparator: (v1, v2) => {
        if (!v1 || !v2) return 0;
        return v1.toString().localeCompare(v2.toString());
      },
      valueGetter: (params: GridValueGetterParams) =>
        (params.row as CBOMediaCollection).publishedPages.length > 0 ? t('Displayed') : t('NotDisplayed'),
    },
    {
      ...defaultHeaderProps,
      align: 'center',
      cellClassName: classes.boldCell,
      description: t('mediaCollections:InfinitePlaylistSwichTooltip'),
      field: 'partOfInfinitePlaylist',
      flex: 1.5,
      headerClassName: classes.mediumCell,
      hide: !canEnableInfiniteMediaCollection,
      renderCell: renderMediaCollectionInfiniteMediaCollectionCell,
      renderHeader: renderMediaCollectionInfiniteMediaCollectionHeader,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        (params.row as CBOMediaCollection).isInfiniteMediaCollectionEnabled,
    },
    {
      ...defaultHeaderProps,
      align: 'center',
      field: '',
      headerClassName: classes.columnHeader,
      headerName: '',
      hide: !!onSelectMediaCollections,
      renderCell: renderMediaCollectionActionsCell,
      sortable: false,
    },
  ];

  if (loading) {
    return null;
  }

  return (
    <MediaCollectionListRoot
      columns={columns}
      components={{
        LoadingOverlay: CustomLoadingGridOverlay,
        NoRowsOverlay: () => (
          // TODO: Styled components
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '50%',
              justifyContent: 'center',
            }}
          >
            <Box display="flex" flexDirection="column">
              <Typography>{t('NoMediaCollectionsFound')}</Typography>
              <CreateButton
                onClick={() => onGoToMediaCollectionEditor?.()}
                sx={{ mt: 1 }}
                text={t('NewMediaCollection')}
              />
            </Box>
          </Box>
        ),
      }}
      disableColumnMenu
      disableSelectionOnClick
      getCellClassName={() => classes.cell}
      getRowClassName={() => classes.gridRow}
      hideFooter
      hideFooterPagination
      onSortModelChange={setSortModel}
      paginationMode="server"
      rowHeight={100}
      rows={mediaCollections}
      sortModel={sortModel}
    />
  );
};

export default MediaCollectionList;
