import { EventStatus, ProductId } from '@bellepoque/api-contracts';

export type CBOLiveReplayCount = {
  live: number;
  replay: number;
};

export type CBODeviceCounts = {
  desktop: number;
  mobile: number;
  tablet: number;
};

export type CBORemindersCounts = {
  calendar: number;
  sms: number;
};

export type CBOEventStatisticsProduct = {
  id: ProductId;
  title: string;
};

export type CBOProductStatisticsWithoutSales = {
  cart: number;
  validated: number;
  views: number;
};

export type CBOProductStatisticsWithSales = CBOProductStatisticsWithoutSales & { purchased: number };

export type CBOProductStatistics = CBOProductStatisticsWithoutSales | CBOProductStatisticsWithSales;

export type CBOProductsStatisticsWithSales = Record<ProductId, CBOProductStatisticsWithSales>;
export type CBOProductsStatisticsWithoutSales = Record<ProductId, CBOProductStatisticsWithoutSales>;
export type CBOProductsStatistics = CBOProductsStatisticsWithoutSales | CBOProductsStatisticsWithSales;
//

export type CBOEventEngagementStatistics = {
  chatMessages: number;
  chatUsers: number;
  likes: CBOLiveReplayCount;
  reminders: CBORemindersCounts;
  seenProductPages: CBOLiveReplayCount;
};

export type CBOEventTrafficStatistics = {
  averageAttendancePercentage: CBOLiveReplayCount;
  averageAttendanceTime: CBOLiveReplayCount;
  deviceCounts: CBODeviceCounts;
  maxSimultaneousViewers: number;
  totalAttendanceTime: CBOLiveReplayCount;
  totalLiveDuration: number;
  uniqueViewers: CBOLiveReplayCount;
  views: CBOLiveReplayCount;
};

type CBOEventECommerceStatisticsWithoutSales = {
  addedProducts: CBOLiveReplayCount;
  cartsAmounts: CBOLiveReplayCount | null;
  filledCarts: CBOLiveReplayCount;
  validatedCarts: CBOLiveReplayCount;
  validatedCartsAmounts: CBOLiveReplayCount | null;
  validatedProducts: CBOLiveReplayCount;
};

export type CBOEventECommerceStatisticsWithSales = CBOEventECommerceStatisticsWithoutSales & {
  purchasedAmounts: CBOLiveReplayCount;
  purchasedCarts: CBOLiveReplayCount;
};

export type CBOEventECommerceStatistics =
  | CBOEventECommerceStatisticsWithoutSales
  | CBOEventECommerceStatisticsWithSales;

export type CBOEventStatisticsPreview = {
  id: string;
  imageUrl: string;
  productsCount: number;
  showTime: Date | null;
  status: EventStatus;
  title: string;
};

export type CBOShoppableVideoStatisticsPreviewWithoutSales = {
  id: string;
  imageUrl: string | null;
  productTitle: string;
  title: string;
  views: number;
};

export type CBOShoppableVideoStatisticsPreviewWithSales = CBOShoppableVideoStatisticsPreviewWithoutSales & {
  purchasedAmounts: number;
  purchasedCarts: number;
};

export type CBOShoppableVideoStatisticsPreview =
  | CBOShoppableVideoStatisticsPreviewWithoutSales
  | CBOShoppableVideoStatisticsPreviewWithSales;
//

export type CBOShoppableVideosTrafficStatistics = {
  totalAttendanceTime: number;
  views: number;
};

export type CBOShoppableVideosECommerceStatisticsWithSales = {
  purchasedAmounts: number;
  purchasedCarts: number;
};

//

export type CBOTenantStatisticsWithoutSales = {
  events: {
    eCommerce: CBOEventECommerceStatisticsWithoutSales;
    engagement: CBOEventEngagementStatistics;
    eventStatisticsPreviews: CBOEventStatisticsPreview[];
    liveCount: number;
    traffic: CBOEventTrafficStatistics;
  };
  shoppables: {
    shoppableVideoStatisticsPreviews: CBOShoppableVideoStatisticsPreviewWithoutSales[];
    traffic: CBOShoppableVideosTrafficStatistics;
  };
};

export type CBOTenantStatisticsWithSales = {
  events: {
    eCommerce: CBOEventECommerceStatisticsWithSales;
    engagement: CBOEventEngagementStatistics;
    eventStatisticsPreviews: CBOEventStatisticsPreview[];
    liveCount: number;
    traffic: CBOEventTrafficStatistics;
  };
  shoppables: {
    eCommerce: CBOShoppableVideosECommerceStatisticsWithSales;
    shoppableVideoStatisticsPreviews: CBOShoppableVideoStatisticsPreviewWithSales[];
    traffic: CBOShoppableVideosTrafficStatistics;
  };
};

export type CBOTenantStatistics = CBOTenantStatisticsWithoutSales | CBOTenantStatisticsWithSales;

//

export type CBOEventStatisticsWithoutSales = {
  eCommerce: CBOEventECommerceStatisticsWithoutSales;
  engagement: CBOEventEngagementStatistics;
  productsStatistics: CBOProductsStatisticsWithoutSales;
  traffic: CBOEventTrafficStatistics;
};

export type CBOEventStatisticsWithSales = {
  eCommerce: CBOEventECommerceStatisticsWithSales;
  engagement: CBOEventEngagementStatistics;
  productsStatistics: CBOProductsStatisticsWithSales;
  traffic: CBOEventTrafficStatistics;
};

export type CBOEventStatistics = CBOEventStatisticsWithoutSales | CBOEventStatisticsWithSales;

export class CBOEventEcommerceStatisticsGuards {
  static isCBOEventECommerceStatisticsWithSales(
    eCommerceStatistics: CBOEventECommerceStatistics,
  ): eCommerceStatistics is CBOEventECommerceStatisticsWithSales {
    return 'purchasedAmounts' in eCommerceStatistics && 'purchasedCarts' in eCommerceStatistics;
  }
}

export class CBOTenantStatisticsGuards {
  static isCBOTenantStatisticsWithSales(
    tenantStatistics: CBOTenantStatistics,
  ): tenantStatistics is CBOTenantStatisticsWithSales {
    return CBOEventEcommerceStatisticsGuards.isCBOEventECommerceStatisticsWithSales(tenantStatistics.events.eCommerce);
  }
}

export class CBOProductStatisticsGuards {
  static isCBOProductStatisticsWithSales(
    productStatistics: CBOProductStatistics,
  ): productStatistics is CBOProductStatisticsWithSales {
    return 'purchased' in productStatistics;
  }
}

export class CBOEventStatisticsGuards {
  static isCBOEventStatisticsWithSales(
    eventStatistics: CBOEventStatistics,
  ): eventStatistics is CBOEventStatisticsWithSales {
    return CBOEventEcommerceStatisticsGuards.isCBOEventECommerceStatisticsWithSales(eventStatistics.eCommerce);
  }
}
