import { V1 } from '@bellepoque/api-contracts';

import { CBOMediaCollection } from '../../domain/CBOMediaCollection';
import { createAppAsyncThunk } from '../../store/create-app-async-thunk';
import { resetCreateMediaCollection as resetCreateMediaCollectionAction } from '../../store/slices/media-collections.slice';

export type CreateMediaCollectionRequest = {
  payload: Pick<CBOMediaCollection, 'id' | 'isInfiniteMediaCollectionEnabled' | 'medias' | 'title'>;
  tenantId: string;
};

export const createMediaCollection = createAppAsyncThunk(
  'media-collections/create-collection',
  async (
    { payload, tenantId }: CreateMediaCollectionRequest,
    { extra: { mediaCollectionsGateway } },
  ): Promise<void> => {
    const dto: V1.api.CreateMediaCollectionDTO = {
      ...payload,
      medias: payload.medias.map((media) => {
        return {
          ...media,
          id: media.type === 'replay-chapter' ? media.replayId : media.id,
        };
      }),
    };
    return mediaCollectionsGateway.createMediaCollection({ dto, tenantId });
  },
);

export const resetCreateMediaCollection = () => {
  return resetCreateMediaCollectionAction();
};
