import { V1 } from '@bellepoque/api-contracts';
import { Facebook, WarningRounded } from '@mui/icons-material';
import { Avatar, Box, CircularProgress, Grid, Typography, styled } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CBOFacebookPage, CBOFacebookUser } from '../../../../../../core/domain/CBOFacebookAccount';
import { CBOFacebookRestreamingConfiguration } from '../../../../../../core/domain/Restreaming';
import { CommandStatus } from '../../../../../../core/store/state/utils';
import ConnectFacebookAccount from './ConnectFacebookAccount';
import SelectFacebookAccount from './SelectFacebookAccount';
import SelectedFacebookAccount from './SelectedFacebookAccount';

type FacebookRestreamingSectionProps = {
  areButtonsDisabled: boolean;
  canEnableMultistream: boolean;
  configuredAccount: CBOFacebookRestreamingConfiguration | null;
  initStatus: CommandStatus;
  isConnecting: boolean;
  onChange: (value: CBOFacebookRestreamingConfiguration | null) => void;
  onConnect: () => void;
  onDisconnect: () => void;
  onInit: () => void;
  pages: CBOFacebookPage[];
  tokenExpiresAt: Date | null;
  user: CBOFacebookUser | null;
  userAccessToken: string | null;
};

const Container = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  margin: `${theme.spacing(2)} 0`,
  padding: `0 ${theme.spacing(3)}`,
  width: '100%',
}));

const TitleContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'initial',
});

export default function FacebookRestreamingSection({
  areButtonsDisabled,
  canEnableMultistream,
  configuredAccount,
  initStatus,
  isConnecting,
  onChange,
  onConnect,
  onDisconnect,
  onInit,
  pages,
  tokenExpiresAt,
  user,
  userAccessToken,
}: FacebookRestreamingSectionProps) {
  const { t } = useTranslation('events');
  const isUserLoggedIn = !!user;
  const initFailed = initStatus === 'error';

  const hasExpired = configuredAccount && configuredAccount.expiresAt < new Date();

  useEffect(() => {
    if (initStatus === 'notRequested') {
      onInit();
    }
  }, []);

  const handleSelectPageAccount = useCallback(
    (pageId: string) => {
      if (!user || !tokenExpiresAt) return;

      const selectedPage = pages.find(({ id }) => pageId === id);
      if (selectedPage) {
        onChange({
          accessToken: selectedPage.accessToken,
          expiresAt: tokenExpiresAt,
          id: selectedPage.id,
          name: selectedPage.name,
          picture: selectedPage.picture,
          type: V1.api.FacebookRestreamingAccountTypeSchema.enum.PAGE,
        });
      }
    },
    [user, tokenExpiresAt, onChange],
  );

  const handleSelectUserAccount = useCallback(() => {
    if (!user || !userAccessToken || !tokenExpiresAt) return;
    onChange({
      accessToken: userAccessToken,
      expiresAt: tokenExpiresAt,
      id: user.id,
      name: user.name,
      picture: user.picture,
      type: V1.api.FacebookRestreamingAccountTypeSchema.enum.USER,
    });
  }, [user, userAccessToken, tokenExpiresAt, onChange]);

  const handleRemoveAccount = () => {
    onChange(null);
  };

  return (
    <Container item xs={12}>
      <Box alignItems="center" display="flex" flex={1} justifyContent="space-between" mb={1}>
        <Box alignItems="center" display="flex" gap={2}>
          <Avatar
            sx={{
              bgcolor: (theme) => (canEnableMultistream ? theme.palette.socials.facebook : theme.palette.grey[500]),
            }}
          >
            <Facebook />
          </Avatar>
          <TitleContainer>
            <Typography fontSize="1em" fontWeight="bold" variant="titleFont">
              {t('common:Socials.Facebook')}
            </Typography>
          </TitleContainer>
        </Box>
        <Box>{hasExpired && <WarningRounded color="error" />}</Box>
      </Box>

      {initStatus === 'success' && isConnecting ? (
        <Box alignItems="center" display="flex" flex={1} justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {configuredAccount ? (
            <SelectedFacebookAccount
              account={configuredAccount}
              areButtonsDisabled={areButtonsDisabled || !canEnableMultistream}
              expiresAt={configuredAccount.expiresAt}
              onRemove={handleRemoveAccount}
            />
          ) : (
            <>
              {isUserLoggedIn ? (
                <SelectFacebookAccount
                  areButtonsDisabled={areButtonsDisabled || !canEnableMultistream}
                  onDisconnect={onDisconnect}
                  onSelectPage={handleSelectPageAccount}
                  onSelectUser={handleSelectUserAccount}
                  pages={pages}
                  user={user}
                />
              ) : (
                <ConnectFacebookAccount
                  errorText={initFailed ? t('FacebookRestreaming.InitError') : undefined}
                  isButtonDisabled={areButtonsDisabled || !canEnableMultistream || initFailed}
                  onConnect={onConnect}
                />
              )}
            </>
          )}
        </Box>
      )}
    </Container>
  );
}
