import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { routes } from '../../routes';
import MediaCollectionsTemplate from '../../templates/media-collections/MediaCollectionsTemplate';
import { createMediaCollectionsViewModel } from './MediaCollections.viewmodel';

const MediaCollections = () => {
  const viewModel = useSelector(createMediaCollectionsViewModel({ dispatch: useDispatch() }));

  const {
    canEnableInfiniteMediaCollection,
    currentMediaCollection,
    deleteMediaCollection,
    deleteMediaCollectionStatus,
    fetchMediaCollections,
    fetchOneMediaCollection,
    getPages,
    maxPublishedShoppablesCount,
    mediaCollectionPagesUpdateStatus,
    mediaCollectionsFetchingStatus,
    mediaCollections,
    publishedShoppablesCount,
    tenantId,
    tenantName,
    trackUserJourneyEvent,
    updateMediaCollectionSettings,
  } = viewModel;

  const navigate = useNavigate();

  useEffect(() => {
    fetchMediaCollections();
    getPages();
  }, [tenantId]);

  useEffect(() => {
    trackUserJourneyEvent();
  }, []);

  const handleChangeIsInfiniteMediaCollectionEnabled = (
    mediaCollectionId: string,
    isInfiniteMediaCollectionEnabled: boolean,
  ) => {
    const mediaCollection = mediaCollections.find((mediaCollection) => mediaCollection.id === mediaCollectionId);

    if (mediaCollection) {
      updateMediaCollectionSettings(mediaCollectionId, {
        isInfiniteMediaCollectionEnabled,
        medias: mediaCollection.medias,
        title: mediaCollection.title,
      });
    }
  };

  const handleGoToMediaCollectionEditor = (mediaCollectionId?: string) => {
    navigate(
      mediaCollectionId
        ? routes.playlists.mediaCollection({ mediaCollectionId })
        : routes.playlists.new({ mediaCollectionMediaId: undefined }),
    );
  };

  const handleFetchMediaCollection = (mediaCollectionId: string) => {
    if (currentMediaCollection?.id !== mediaCollectionId) {
      fetchOneMediaCollection(mediaCollectionId);
    }
  };

  return (
    <MediaCollectionsTemplate
      canEnableInfiniteMediaCollection={canEnableInfiniteMediaCollection}
      currentMediaCollection={currentMediaCollection}
      deleteMediaCollectionStatus={deleteMediaCollectionStatus}
      loading={mediaCollectionsFetchingStatus === 'pending'}
      maxPublishedShoppablesCount={maxPublishedShoppablesCount}
      mediaCollectionPagesUpdateStatus={mediaCollectionPagesUpdateStatus}
      mediaCollections={mediaCollections}
      onChangeIsInfiniteMediaCollectionEnabled={handleChangeIsInfiniteMediaCollectionEnabled}
      onDeleteMediaCollection={deleteMediaCollection}
      onFetchMediaCollection={handleFetchMediaCollection}
      onGoToMediaCollectionEditor={handleGoToMediaCollectionEditor}
      publishedShoppablesCount={publishedShoppablesCount}
      tenantName={tenantName}
    />
  );
};

export default MediaCollections;
