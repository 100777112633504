import { AppDispatch, State } from '../../../core/store';
import { fetchOneEvent } from '../../../core/usecases/event/crud/fetch-event';
import { fetchEventStatistics } from '../../../core/usecases/event/statistics/fetch-event-statistics';
import { selectTenant } from '../../../core/usecases/tenants/select-tenant';
import { trackUserJourneyEvent } from '../../../core/usecases/user-journey-tracing/track-user-journey-event';
import { eventToUserJourneyData } from '../../../utils/event-to-user-journey-data';

export const createEventStatisticsViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const { currency, id: tenantId, name: tenantName } = state.tenants.currentTenant;
    const event = state.events.currentEvent;
    const eventFetchingStatus = state.events.eventFetching.status;
    const eventStatisticsFetchingStatus = state.events.eventStatisticsFetching.status;
    const statistics = state.events.currentEventStatistics;

    return {
      currency,
      event,
      eventFetchingStatus,
      eventStatisticsFetchingStatus,
      fetchEvent: (eventId: string) => {
        dispatch(fetchOneEvent({ eventId, tenantId }));
      },
      fetchEventStatistics: (eventId: string) => {
        dispatch(fetchEventStatistics({ eventId }));
      },
      selectEventTenant: () => {
        if (event) {
          dispatch(selectTenant({ tenantId: event.tenantId }));
        }
      },
      statistics,
      tenantId,
      trackUserJourneyEvent: () => {
        if (event) {
          dispatch(
            trackUserJourneyEvent({
              data: {
                ...eventToUserJourneyData(event),
                'Tenant id': tenantId,
                'Tenant name': tenantName,
              },
              name: 'Analytics - Event statistics',
            }),
          );
        }
      },
    };
  };
