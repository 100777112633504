import { Cms } from '@bellepoque/api-contracts';

import { UserJourneyEvent } from '../core/gateways/user-journey-tracing-gateway';
import { AppDispatch, State } from '../core/store';
import { hasCmsLandingPage } from '../core/usecases/landing-page/has-cms-landing-page';
import { checkShopifyScopesAreUpToDate } from '../core/usecases/shopify/check-shopify-scopes-are-up-to-date';
import { selectTenant } from '../core/usecases/tenants/select-tenant';
import { NextTutorialStepParams, nextTutorialStep } from '../core/usecases/tutorial/next-tutorial-step';
import { toggleTutorial } from '../core/usecases/tutorial/toggle-tutorial';
import { initUserJourneyTracing } from '../core/usecases/user-journey-tracing/init-user-journey-tracing';
import { trackUserJourneyEvent } from '../core/usecases/user-journey-tracing/track-user-journey-event';
import { login } from '../core/usecases/user/login';
import { logout } from '../core/usecases/user/logout';

export const createAppViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const { currentUser, isAuthenticated } = state.authentication;
    const { currentTenant, tenants } = state.tenants;
    const { currentStep: currentTutorialStep, highlightedTab, isShown: isTutorialShown } = state.tutorial;
    const hasShopify = currentTenant.cms === Cms.Shopify;

    return {
      checkHasCmsLandingPage: () => {
        if (hasShopify) {
          dispatch(hasCmsLandingPage({ tenantId: currentTenant.id }));
        }
      },
      checkShopifyScopesAreUpToDate: () => {
        dispatch(checkShopifyScopesAreUpToDate(currentTenant.id));
      },
      currentTenant,
      currentTutorialStep,
      currentUser,
      highlightedTab,
      initUserJourneyTracing: () => {
        initUserJourneyTracing({
          email: currentUser.email as string,
          tenantId: currentTenant.id,
          tenantName: currentTenant.name,
        });
      },
      isAuthenticated,
      isTutorialShown,
      login: () => {
        dispatch(login());
      },
      logout: () => {
        dispatch(logout());
      },
      nextTutorialStep: (params: NextTutorialStepParams) => {
        dispatch(nextTutorialStep(params));
      },
      selectTenant: (tenantId: string) => {
        dispatch(selectTenant({ tenantId }));
      },
      tenants,
      toggleTutorial: (isShown: boolean) => {
        dispatch(toggleTutorial({ isShown }));
      },
      trackUserJourneyEvent: (event: UserJourneyEvent) => {
        dispatch(trackUserJourneyEvent(event));
      },
    };
  };
