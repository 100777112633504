import { BILLING_PLANS, BillingPlanId } from '@bellepoque/api-contracts';

import { AppDispatch, State } from '../../../../../core/store';
import { fetchOneMediaCollection } from '../../../../../core/usecases/media-collections/fetch-one-media-collection';
import {
  UpdateMediaCollectionSettingsRequest,
  updateMediaCollectionSettings,
} from '../../../../../core/usecases/media-collections/update-media-collection-settings';
import { getPagesForMediaCollections } from '../../../../../core/usecases/shopify/get-pages-for-media-collections';

// TODO: Test view model

export const SelectMediaCollectionDialogViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const {
      currentMediaCollection,
      mediaCollections,
      mediaCollectionPagesUpdate: { status: mediaCollectionPagesUpdateStatus },
    } = state.mediaCollections;
    const { activeSubscriptionId, id: tenantId } = state.tenants.currentTenant;
    const { pagesForMediaCollectionsFetching } = state.shopify;

    const currentPlanId: BillingPlanId = activeSubscriptionId ?? 'live_access';

    return {
      canEnableInfiniteMediaCollection: BILLING_PLANS[currentPlanId].canEnableInfiniteMediaCollection,
      currentMediaCollection,
      currentShoppable: state.shoppables.currentShoppable,
      fetchOneMediaCollection: (mediaCollectionId: string) => {
        dispatch(fetchOneMediaCollection({ mediaCollectionId, tenantId }));
      },
      getPages: () => {
        dispatch(getPagesForMediaCollections(tenantId));
      },
      getPagesStatus: pagesForMediaCollectionsFetching.status,
      maxPublishedShoppablesCount: BILLING_PLANS[currentPlanId].maxPublishedShoppables ?? null,
      mediaCollectionPagesUpdateStatus,
      mediaCollectionSettingsUpdateStatus: state.mediaCollections.mediaCollectionSettingsUpdate.status,
      mediaCollections,
      tenantId,
      tenantName: state.tenants.currentTenant.name,
      updateMediaCollectionSettings: (
        mediaCollectionId: string,
        payload: UpdateMediaCollectionSettingsRequest['payload'],
      ) => {
        dispatch(updateMediaCollectionSettings({ mediaCollectionId, payload, tenantId }));
      },
    };
  };
