import { Box, FormControlLabel, FormGroup, Grid, Switch, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CBOCurrency } from '../../../../core/domain/CBOCurrency';
import { CBOLiveEventStatistics } from '../../../../core/domain/CBOLiveEventStatistics';
import { formatCurrency } from '../../../../utils/currency-formatter';
import Counter from '../../atoms/event/Counter';

const DisplayActiveViewersLabel = styled(Typography)({
  fontSize: '.7em',
  textAlign: 'initial',
  textTransform: 'uppercase',
});

const Container = styled(Box)(({ theme }) => ({
  flex: 1,
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
  textAlign: 'center',
}));

interface LiveStatsProps {
  currency: CBOCurrency;
  currentLiveEventStatistics: CBOLiveEventStatistics;
  hasSalesTracker: boolean;
  isLiveViewersCountDisplaySwitchShown: boolean;
  isLiveViewersCountDisplayed: boolean;
  onToggleLiveViewersCountDisplay: (isDisplayed: boolean) => void;
}
export default function LiveStats({
  currency,
  currentLiveEventStatistics,
  isLiveViewersCountDisplayed,
  isLiveViewersCountDisplaySwitchShown,
  hasSalesTracker,
  onToggleLiveViewersCountDisplay,
}: LiveStatsProps) {
  const { t } = useTranslation('events');
  return (
    <Container>
      <Grid alignItems="center" container gap={1} justifyContent="space-evenly">
        {isLiveViewersCountDisplaySwitchShown && (
          <Box ml={4} width="150px">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked={isLiveViewersCountDisplayed}
                    onChange={(_event, checked) => onToggleLiveViewersCountDisplay(checked)}
                  />
                }
                label={<DisplayActiveViewersLabel>{t('DisplayActiveViewers')}</DisplayActiveViewersLabel>}
              />
            </FormGroup>
          </Box>
        )}
        <Counter
          helperText={t('counters.TotalUniqueViewersHelper')}
          title={t('counters.TotalUniqueViewers')}
          value={currentLiveEventStatistics.totalLiveUniqueViewers}
        />
        <Counter
          helperText={t('counters.ActiveViewersHelper')}
          title={t('counters.ActiveViewers')}
          value={currentLiveEventStatistics.countActiveViewers}
        />
        <Counter
          helperText={t('counters.ActiveChatUsersHelper')}
          title={t('counters.ActiveChatUsers')}
          value={currentLiveEventStatistics.countActiveChatUsers}
        />
        <Counter
          helperText={t('counters.LikesHelper')}
          title={t('counters.Likes')}
          value={currentLiveEventStatistics.countLiveLikes}
        />
        <Counter
          helperText={t('counters.ProductPageOpenedHelper')}
          title={t('counters.ProductPageOpened')}
          value={currentLiveEventStatistics.countLiveProductPageOpened}
        />
        <Counter
          helperText={t('counters.LiveCartAmountHelper')}
          title={t('counters.LiveCartAmount')}
          value={formatCurrency(currentLiveEventStatistics.totalLiveCartAmount, currency)}
        />
        {hasSalesTracker ? (
          <Counter
            helperText={t('counters.LivePurchasedAmountHelper')}
            title={t('counters.LivePurchasedAmount')}
            value={formatCurrency(currentLiveEventStatistics.totalLivePurchasedAmount, currency)}
          />
        ) : (
          <Counter
            helperText={t('counters.ValidateCartHelper')}
            title={t('counters.ValidateCart')}
            value={currentLiveEventStatistics.countLiveValidateCart}
          />
        )}
      </Grid>
    </Container>
  );
}
