import { Button, lighten, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import YoutubeLogoMono from '../../../assets/youtube-logo-mono.png';

interface LinkYoutubeAccountButtonProps {
  disabled: boolean;
  onClick: () => void;
}

const StyledButton = styled(Button)(({ disabled, theme }) => ({
  '&:disabled': {
    color: theme.palette.common.white,
    opacity: 0.8,
  },
  '&:hover': {
    backgroundColor: lighten(theme.palette.socials.youtube, 0.2),
  },
  backgroundColor: disabled ? theme.palette.grey[500] : theme.palette.socials.youtube,
  color: theme.palette.common.white,
  display: 'flex',
  gap: theme.spacing(1),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  textTransform: 'initial',
}));

export default function LinkYoutubeAccountButton({ disabled, onClick }: LinkYoutubeAccountButtonProps) {
  const { t } = useTranslation('events', { keyPrefix: 'YoutubeRestreaming' });

  return (
    <StyledButton disabled={disabled} onClick={onClick}>
      <img height="15px" src={YoutubeLogoMono} />
      {t('LinkAccount')}
    </StyledButton>
  );
}
