import { BILLING_PLANS, BillingPlanId, V1, YoutubeAccessToken } from '@bellepoque/api-contracts';

import { UserJourneyEvent } from '../../../../../core/gateways/user-journey-tracing-gateway';
import { AppDispatch, State } from '../../../../../core/store';
import { resetFetchYoutubeLoginUrl } from '../../../../../core/store/slices/youtube.slice';
import { updateEvent } from '../../../../../core/usecases/event/crud/update-event';
import { connectFacebook } from '../../../../../core/usecases/event/facebook/connect-facebook';
import { disconnectFacebook } from '../../../../../core/usecases/event/facebook/disconnect-facebook';
import { initFacebook } from '../../../../../core/usecases/event/facebook/init-facebook';
import { disconnectYoutube } from '../../../../../core/usecases/event/youtube/disconnect-youtube';
import { fetchYoutubeChannels } from '../../../../../core/usecases/event/youtube/fetch-youtube-channels';
import { fetchYoutubeLoginUrl } from '../../../../../core/usecases/event/youtube/fetch-youtube-login-url';
import { trackUserJourneyEvent } from '../../../../../core/usecases/user-journey-tracing/track-user-journey-event';

export const createMultistreamViewModel =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (state: State) => {
    const { activeSubscriptionId, id: tenantId, name: tenantName } = state.tenants.currentTenant;
    const { status: eventUpdateStatus } = state.events.eventUpdate;

    const facebookConnectedUser = state.facebook.connectedUser;
    const facebookConnectingStatus = state.facebook.facebookConnecting.status;
    const facebookInitStatus = state.facebook.facebookInitializing.status;

    const youtubeConnectedUser = state.youtube.connectedUser;
    const youtubeLoginUrl = state.youtube.loginUrl;

    const currentPlanId: BillingPlanId = activeSubscriptionId ?? 'live_access';

    return {
      canEnableMultistream: BILLING_PLANS[currentPlanId].canEnableMultistream,
      facebookInitStatus,
      facebookPages: facebookConnectedUser?.pages ?? [],
      facebookTokenExpiresAt: facebookConnectedUser?.expiresAt ?? null,
      facebookUser: facebookConnectedUser?.user ?? null,
      facebookUserAccessToken: facebookConnectedUser?.accessToken ?? null,
      fetchLoginUrlStatus: state.youtube.loginUrlFetching.status,
      isConnectingToFacebook: facebookConnectingStatus === 'pending',
      isUpdatingEvent: eventUpdateStatus === 'pending',
      onConnectFacebook: () => dispatch(connectFacebook()),
      onDisconnectFacebook: () => dispatch(disconnectFacebook()),
      onDisconnectYoutube: () => dispatch(disconnectYoutube()),
      onFetchYoutubeChannels: (token: YoutubeAccessToken) => dispatch(fetchYoutubeChannels(token)),
      onFetchYoutubeLoginUrl: () => dispatch(fetchYoutubeLoginUrl()),
      onInitFacebook: () => dispatch(initFacebook()),
      onResetFetchYoutubeLoginUrl: () => dispatch(resetFetchYoutubeLoginUrl()),
      tenantId,
      tenantName,
      trackUserJourneyEvent: (userJourneyEvent: UserJourneyEvent) => {
        dispatch(trackUserJourneyEvent(userJourneyEvent));
      },
      updateEvent: (eventId: string, payload: V1.api.UpdateEventDTO) =>
        dispatch(
          updateEvent({
            id: eventId,
            payload,
          }),
        ),
      youtubeConnectedUser,
      youtubeLoginUrl,
    };
  };
